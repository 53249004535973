import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {GRID_TREE_DATA_GROUPING_FIELD} from '@mui/x-data-grid-pro';
import Box from "@mui/material/Box";
import {formatAmount, formatStatus, removeTimezone} from "../utils/Formater";
import {convertDateToMMYY} from "../utils/Coverter";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const centresColumns = (handleCompanySelect) => [
  {
    field: 'code',
    headerName: 'ID',
  },
  {
    field: 'name',
    headerName: 'Středisko',
    minWidth: 385,
    flex: 2,
    type: 'string',
    renderCell: (params) => (
      <div style={{cursor: 'pointer'}} onClick={() => {
        handleCompanySelect(params.row.id, params.row.name);
      }}
      >{params.value}</div>
    )
  },
];

export const companiesColumns = (handleCompanySelect) => [
  {
    field: 'name',
    headerName: 'Firma',
    minWidth: 385,
    flex: 2,
    type: 'string',
    renderCell: (params) => (
      <div style={{cursor: 'pointer'}} onClick={() => {
        handleCompanySelect(params.row.id, params.row.name);
      }}
      >{params.value}</div>
    )
  },
];

export const phasesColumns = (handleUpdateClick) => [
  {
    field: 'edit',
    headerName: '',
    width: 60,
    sortable: false,
    disableClickEventBubbling: true,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params) => (
      (params.row.level === "contract") &&
      <IconButton
        sx={{color: "inherit"}}
        onClick={() =>
          handleUpdateClick(params.row.contractCode === "" ? params.row : params.row.contractCode)
        }
      >
        <VisibilityIcon sx={{fontSize: 18}}/>
      </IconButton>
    ),
  },
  {
    field: GRID_TREE_DATA_GROUPING_FIELD,
  },
  {
    field: 'contractName',
    headerName: 'Název zakázky',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'assumptionValue',
    headerName: 'Plán',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => (
      Math.abs(params.row.costAssumption - params.row.sumCostAssumption) < 1 ? formatAmount(params.row.assumptionValue) :
        <Tooltip
          title={params.row.contractCode === "" ? " Jedna ze zakázek v tomto měsíci obsahuje chybný plán" : "Plán pro tuto zakázku je: " + params.row.costAssumption + ". Kdežto suma plánů pro jednotlivé měsíce je: "
            + params.row.sumCostAssumption} PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: params.row.contractCode === "" ? 'orange' : 'red',
              color: 'white',
              fontSize: 10,
              padding: '8px 12px',
            }
          }
        }}>
          <div style={{
            alignItems: 'center',
            display: 'inline-flex',
            color: params.row.level === "contract" ? 'red' : 'black'
          }}>
            {params.row.level === "contract" && <ErrorIcon sx={{color: 'red', fontSize: 18, marginRight: 0.5}}/>}
            {params.row.level === "month" && <WarningIcon sx={{color: 'orange', fontSize: 18, marginRight: 0.5}}/>}
            {params.rowNode.depth === 0 && formatAmount(params.row.assumptionValue) === ''
              ? 0
              : formatAmount(params.row.assumptionValue)}
          </div>
        </Tooltip>
    )
  },
  {
    field: 'scopeValue',
    headerName: 'Smlouva',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => (
      Math.abs(params.row.scope - params.row.sumScope) < 1 ? formatAmount(params.row.scopeValue) :
        <Tooltip
          title={params.row.contractCode === "" ? " Jedna ze zakázek v tomto měsíci obsahuje chybnou smlouvu" : "Smlouva pro tuto zakázku je: " + params.row.scope + ". Kdežto suma smluv pro jednotlivé měsíce je: "
            + params.row.sumScope} PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: params.row.contractCode === "" ? 'orange' : 'red',
              color: 'white',
              fontSize: 10,
              padding: '8px 12px',
            }
          }
        }}>
          <div style={{
            alignItems: 'center',
            display: 'inline-flex',
            color: params.row.level === "contract" ? 'red' : 'black'
          }}>
            {params.row.level === "contract" && <ErrorIcon sx={{color: 'red', fontSize: 18, marginRight: 0.5}}/>}
            {params.row.level === "month" && <WarningIcon sx={{color: 'orange', fontSize: 18, marginRight: 0.5}}/>}
            {params.rowNode.depth === 0 && formatAmount(params.row.scopeValue) === ''
              ? 0
              : formatAmount(params.row.scopeValue)}
          </div>
        </Tooltip>
    )
  },
  {
    field: 'difference',
    headerName: 'Rozdíl',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => {
      if (params.rowNode.depth === 0 && !params.value) {
        return 0
      }
      return formatAmount(params.value)
    }
  },
  {
    field: 'company',
    headerName: 'Dodavatel',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'name',
    headerName: 'Popis',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'statusPaid',
    headerName: 'Uhrazenost',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'amountBase',
    headerName: 'Základ',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'amountVat',
    headerName: 'DPH',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'amountTotal',
    headerName: 'Celkem',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'actualPayment',
    headerName: 'Skutečná uhrada',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'expoDate',
    headerName: 'Vystaveno',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params) return null; // Ensure empty value is returned for missing data

      const date = new Date(removeTimezone(params)); // Ensure you pass params.value
      return Number.isNaN(date.getTime()) ? null : date; // Return null for invalid dates
    },
    valueFormatter: (params) =>
      params
        ? new Intl.DateTimeFormat('cs-CZ').format(params)
        : '',
  },
  {
    field: 'dueDate',
    headerName: 'Splatnost',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params) return null; // Ensure empty value is returned for missing data

      const date = new Date(removeTimezone(params)); // Ensure you pass params.value
      return Number.isNaN(date.getTime()) ? null : date; // Return null for invalid dates
    },
    valueFormatter: (params) =>
      params
        ? new Intl.DateTimeFormat('cs-CZ').format(params)
        : '',
  },
  {
    field: 'paymentDate',
    headerName: 'Zaplaceno',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      if (!params) return null; // Ensure empty value is returned for missing data

      const date = new Date(removeTimezone(params)); // Ensure you pass params.value
      return Number.isNaN(date.getTime()) ? null : date; // Return null for invalid dates
    },
    valueFormatter: (params) =>
      params
        ? new Intl.DateTimeFormat('cs-CZ').format(params)
        : '',
  },
];

export const contractsColumns = (handleEditClick, handleDuplicateClick, isCompany) => [
  {
    field: 'edit',
    headerName: '',
    width: isCompany ? 100 : 50,
    sortable: false,
    disableClickEventBubbling: true,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params) => (
      <Box>
        <IconButton
          sx={{color: "inherit", padding: 0.5}}
          onClick={() => handleEditClick(params.row.code)}
        >
          <VisibilityIcon sx={{fontSize: 18, padding: 0}}/>
        </IconButton>
        {isCompany && <IconButton
          sx={{color: "inherit", padding: 0.5}}
          onClick={() => handleDuplicateClick(params.row.code)}
        >
          <ContentCopyIcon sx={{fontSize: 18, padding: 0}}/>
        </IconButton>
        }
      </Box>
    ),
  },
  {
    field: 'code',
    headerName: 'Zak',
    minWidth: 80,
    flex: 1,
    type: 'string',
  },
  {
    field: 'phaseList',
    headerName: 'Fáze',
    minWidth: 100,
    flex: 1,
    type: 'string',
    valueGetter: (params) => {
      return params.map(phase => phase).join(", ")
    }
  },
  {
    field: 'status',
    headerName: 'Stav',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'name',
    headerName: 'Název',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'dictionary',
    headerName: 'Dodavatel',
    minWidth: 150,
    flex: 1,
    type: 'string',
  },
  {
    field: 'startDate',
    headerName: 'Platí od',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
  {
    field: 'endDate',
    headerName: 'Platí do',
    minWidth: 150,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
  {
    field: 'costAssumption',
    headerName: 'Plán',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'scope',
    headerName: 'Smlouva',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'newCostAssumption',
    headerName: 'Nový plán',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'newScope',
    headerName: 'Nová smlouva',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'realized',
    headerName: 'Realizováno',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'remaining',
    headerName: 'Zbývá dočerpat',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },

];

export const groupsAndActivitiesColumns = (months) => [
  ...months.flatMap(month => ([
    {
      field: `assumptions_${month}`,
      headerName: `Plán`,
      width: 120,
      type: 'number',
      valueFormatter: (value) => {
        return formatAmount(value)
      }
    },
    {
      field: `spendings_${month}`,
      headerClassName: `spendings`,
      headerName: `Skutečnost`,
      width: 120,
      type: 'number',
      valueGetter: (params, row) => {
        return row[month] ? row[month] : row[`spendings_${month}`];
      },
      valueFormatter: (value) => {
        return formatAmount(value)
      }
    },
  ])),
  {
    field: 'sum_assumptions',
    headerName: 'Plán',
    width: 120,
    type: 'number',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'sum_reality',
    headerClassName: `spendings`,
    headerName: 'Skutečnost',
    width: 120,
    type: 'number',
    valueGetter: (params, row) => {
      return row[months[months.length - 1]] ? row[months[months.length - 1]] : row[`total_values`];
    },
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'total_assumption_values',
    headerName: 'Plán',
    width: 120,
    type: 'number',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'total_values',
    headerName: 'Skutečnost',
    width: 120,
    type: 'number',
    valueGetter: (params, row) => {
      const today = dayjs().format("YYYY-MM-DDZ");
      return row[convertDateToMMYY(today)] ? row[convertDateToMMYY(today)] : row[`total_values`];
    },
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
];

export const bankColumns = (months) => {
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = String(currentDate.getFullYear()).slice(-2);
  const currentMonthYear = `${currentMonth}${currentYear}`;

  return [
    { field: "label", headerName: "Tok peněz", width: 180, type: "string" },
    ...months.flatMap((month, index) => [
      {
        field: month,
        headerName: month.slice(0, 2) + "/" + month.slice(2),
        headerAlign: "center",
        width: month === currentMonthYear ? 240 : 120,
        type: "number",
        headerClassName: `month`,
        valueGetter: (value, row) => row?.spendings[index] ?? 0,
        valueFormatter: (value) => {
          return formatAmount(value);
        },
      },
    ]),
    {
      field: `sum`,
      headerName: "Suma za období",
      headerAlign: "center",
      width: 240,
      type: "number",
      headerClassName: `month`,
      valueGetter: (value, row) => row?.sum ?? 0,
      valueFormatter: (value) => {
        return formatAmount(value);
      },
    },
    {
      field: `total`,
      headerName: "Suma celkem",
      headerAlign: "center",
      width: 240,
      type: "number",
      valueGetter: (value, row) => row?.total ?? 0,
      valueFormatter: (value) => {
        return formatAmount(value);
      },
    },
  ];
};

export const cashflowColumns = (months, handleLabelClick) => [
  {
    field: "label", headerName: "Fáze projektu", width: 180, type: "string", renderCell: (params) => (
      <div onClick={() => {
        if (handleLabelClick && params.value !== "Celkem") handleLabelClick(params.value)
      }} style={{cursor: handleLabelClick && params.value !== "Celkem" ? "pointer" : "default"}}>
        {params.value}
      </div>
    )
  },
  ...months.flatMap((month, index) => [
    {
      field: `assumptions_${month}`,
      headerName: "Předpis",
      width: 120,
      type: 'number',
      valueGetter: (value, row) => row?.assumptions[index] ?? 0, // Updated
      valueFormatter: (value) => {
        return formatAmount(value)
      },
    },
    {
      field: `spendings_${month}`,
      headerName: "Uhrazeno",
      width: 120,
      headerClassName: `spendings`,
      type: 'number',
      valueGetter: (value, row) => row?.spendings[index] ?? 0, // Updated
      valueFormatter: (value) => {
        return formatAmount(value)
      },
    },
  ]),
  {
    field: `sumAssumption`,
    headerName: "Předpis",
    width: 120,
    type: 'number',
    valueGetter: (value, row) => row?.sumAssumption ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `sum`,
    headerName: "Uhrazeno",
    headerClassName: `spendings`,
    width: 120,
    type: 'number',
    valueGetter: (value, row) => row?.sum ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `totalAssumption`,
    headerName: "Předpis",
    width: 120,
    type: 'number',
    valueGetter: (value, row) => row?.totalAssumption ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `total`,
    headerName: "Uhrazeno",
    width: 120,
    type: 'number',
    valueGetter: (value, row) => row?.total ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
];

export const userColumns = (handleEditClick) => [
  {
    field: 'edit',
    headerName: '',
    width: 70,
    type: 'string',
    renderCell: (params) => (
      <Box>
        <IconButton
          sx={{color: "inherit", padding: 0}}
          onClick={() => handleEditClick(params.row.id)}
        >
          <EditIcon sx={{fontSize: 18, padding: 0}}/>
        </IconButton>
      </Box>
    ),
  },
  {
    field: 'firstname',
    headerName: 'Jméno',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'lastname',
    headerName: 'Příjmení',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'username',
    headerName: 'E-mail',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'active',
    headerName: 'Aktivní',
    minWidth: 100,
    flex: 0.5,
    type: 'boolean',
  },
];

export const tasksColumns = () => [
  {
    field: 'company',
    headerName: 'Firma',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'code',
    headerName: 'Interní číslo',
    minWidth: 100,
    flex: 1,
    type: 'string',
  },
  {
    field: 'movementType',
    headerName: 'Typ pohybu',
    minWidth: 100,
    flex: 1,
    type: 'string',
    valueFormatter: (value) => {
      if (value === "vydej") {
        return "Výdej"
      } else if (value === "prijem") {
        return "Příjem"
      }
    }
  },
  {
    field: 'expoDate',
    headerName: 'Datum zúčtování',
    minWidth: 100,
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
  {
    field: 'amount',
    headerName: 'Částka celkem',
    minWidth: 100,
    flex: 1,
    type: 'number',
    valueFormatter: (value) => {
      return formatAmount(value);
    }
  },
];

export const invoicesColumns = () => [
  {
    field: "code",
    headerName: "Číslo faktury",
    flex: 1,
  },
  {
    field: "company",
    headerName: "Dodavatel",
    flex: 2,
  },
  {
    field: "description",
    headerName: "Popis",
    flex: 2,
  },
  {
    field: "statusPaid",
    headerName: "Uhrazenost",
    flex: 1,
    valueFormatter: (value) => {
      return formatStatus(value)
    }
  },
  {
    field: "amountBase",
    headerName: "Základ",
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: "amountVat",
    headerName: "DPH",
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: "amountTotal",
    headerName: "Celkem",
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: "paymentAmount",
    headerName: "Skutečná úhrada",
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: "expoDate",
    headerName: "Vystaveno",
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
  {
    field: "dueDate",
    headerName: "Splatnost",
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
  {
    field: "paymentDate",
    headerName: "Zaplaceno",
    flex: 1,
    type: 'date',
    valueGetter: (params) => {
      return new Date(removeTimezone(params))
    }
  },
];

export const groupsAndActivitiesGroupingModel = (months) => [
  ...months.map((month) => ({
    groupId: month,
    headerName: month.slice(0, 2) + "/" + month.slice(2),
    headerAlign: "center",
    headerClassName: `spendings`,
    children: [
      {field: `spendings_${month}`, headerName: "Spendings"},
      {field: `assumptions_${month}`, headerName: "Assumptions"},
    ],
  })),
  {
    groupId: 'sum',
    headerName: 'Suma za období',
    headerClassName: `spendings`,
    headerAlign: 'center',
    children: [
      {field: 'sum_reality', headerName: 'sum'},
      {field: 'sum_assumptions', headerName: 'Total Assumption'},
    ],
  },
  {
    groupId: 'total',
    headerName: 'Suma celkem',
    headerAlign: 'center',
    children: [
      {field: 'total_values', headerName: 'sum'},
      {field: 'total_assumption_values', headerName: 'Total Assumption'},
    ],
  },
];


