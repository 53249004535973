import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button, IconButton,
  Tab, Tabs,
  Typography
} from "@mui/material";
import {userCreateSchema, userEditSchema} from "../../../utils/Validations";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid2";
import {useFormik} from 'formik';
import {GlobalDataContext} from "../../GlobalDataProvider";
import {UserInfo} from "./UserInfo";
import {UserPermission} from "./UserPermission";
import toast from "react-hot-toast";
import {toastWithError} from "../../ToastWithError";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {api} from "../../../api/Api";
import {useNavigate} from "react-router-dom";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  maxWidth: '90vh', // Maximum width for PC
  maxHeight: '90vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  borderRadius: "10px",
  bgcolor: 'background.paper',
};

const UserEdit = ({mode, selectedUser, opened, close, updateRows, deleteRow}) => {
  const {userDetails} = useContext(GlobalDataContext);
  const [companies, setCompanies] = useState(userDetails && userDetails.flexiWorkspaceList?.length > 0 ? userDetails.flexiWorkspaceList : []);
  const [tab, setTab] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const modules = ['Skupina', 'Činnosti', 'Střediska', 'Firmy'];
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      confirmPassword: '',
      role: '',
      active: true,
      systemVisibilityList: modules,
      flexiWorkspaceList: companies,
    },
    validationSchema: mode === 'create' ? userCreateSchema : userEditSchema,
    onSubmit: async (values) => {
      submit(values)
    },
  });

  const submit = async (values) => {
    try {
      setSaving(true);
      if (mode === "update" && selectedUser.role === "user") {
        toast.error("Editace role uživatel v tuto chvíli není implementována.");
        setSaving(false);
        return;
      }
      if (!Array.isArray(values.systemVisibilityList) || values.systemVisibilityList.length === 0) {
        toast.error('Uživatel musí vidět alespoň 1 z modulů');
        setSaving(false);
        return;
      }
      if (!Array.isArray(values.flexiWorkspaceList) || values.flexiWorkspaceList.length === 0) {
        toast.error('Uživatel musí mít přístup alespoň k jedné firmě');
        setSaving(false);
        return;
      }
      const response = await api.post(
          `/user/${mode}`,
        {
          userId: mode === "update" ? selectedUser.id : null,
          adminId: mode === "update" ? userDetails.id : null,
          ...values,
        });
      toast.success(`Účet byl ${mode === "create" ? "vytvořen" : "upraven"}!`);
      updateRows(response.data);
      setSaving(false);
      handleClose();
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        navigate("/");
        return;
      }
      toastWithError(error);
      toast.error(`Účet se nepodařilo ${mode === "create" ? "vytvořit" : "upravit"}!`);
      setSaving(false);
    }
  };


  useEffect(() => {
    if (opened) {
      mode === "update" && fetchEditData();
    }
  }, [opened]);

  useEffect(() => {
    if (userDetails?.flexiWorkspaceList && mode === "create" && opened) {
      setCompanies(userDetails.flexiWorkspaceList);
      formik.setFieldValue('flexiWorkspaceList', userDetails.flexiWorkspaceList);
    }
  }, [userDetails, opened]);

  const fetchEditData = async () => {
    setCompanies(userDetails.flexiWorkspaceList);
    formik.setValues({
      firstname: selectedUser.firstname,
      lastname: selectedUser.lastname,
      username: selectedUser.username,
      role: selectedUser.role,
      active: selectedUser.active,
      systemVisibilityList: selectedUser.systemVisibilityList,
      flexiWorkspaceList: selectedUser.flexiWorkspaceList,
    });
  }

  const handleClose = () => {
    close();
    formik.resetForm();
    setTab(0);
  }

  const handleDelete = async () => {
    try {
      setDeleting(true);
      if (selectedUser.username === userDetails.username) {
        toast.error('Nemůžete odstranit svůj účet!');
        setDeleting(false);
        setSaving(false);
        return;
      }
      const response = await api.post(
          `/user/delete`,
        {
          "userId": selectedUser.id
        },
      );
      toast.success(`Uživatel byl smazán!`);
      deleteRow(response.data.user.id);
      setDeleting(false)
      handleClose();
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        navigate("/");
        return;
      }
      toastWithError(error);
      toast.error(`Uživatel se nepodařilo smazat!`);
      setDeleting(false);
    }
  }


  return (
    <Modal open={opened} onClose={handleClose} aria-labelledby="user-edit-modal" aria-describedby="user-edit-form">
      <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1}}>
          <Grid container>
            <Grid size={6}>
              <Typography variant="h5" sx={{p: 3, paddingBottom: "10px", fontWeight: 'bold'}}>
                {mode === 'update' ? 'Úprava uživatele' : 'Vytváření nového uživatele'}
              </Typography>
            </Grid>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', p: 1.5, pb: 0}}>
              <IconButton onClick={handleClose}>
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
          <Tabs value={tab} sx={{paddingLeft: 3, paddingRight: 3}}
                onChange={(e, newValue) => setTab(newValue)}>
            <Tab label="Základní údaje"/>
            <Tab label="Oprávnění" disabled={formik.values.role !== "user"}/>
          </Tabs>
        </Box>
        <Box sx={{p: 3, backgroundColor: '#f8f8f8'}}>
          <Box hidden={tab !== 0}>
            <UserInfo formik={formik}/>
          </Box>
          <Box hidden={tab !== 1}>
            <UserPermission formik={formik} flexiWorkspaceList={companies} listOfModules={modules}/>
          </Box>
        </Box>
        <Box sx={{position: 'sticky', bottom: 0, backgroundColor: '#ffffff'}}>
          <Grid container sx={{p: 3}}>
            <Grid size={6}>
              {mode === "update" &&
                <LoadingButton variant="outlined" disabled={saving} color="error" loading={deleting}
                               loadingPosition="start" onClick={handleDelete} startIcon={<DeleteIcon/>}>
                  Smazat
                </LoadingButton>
              }
            </Grid>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
              <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
              <LoadingButton loading={saving} disabled={deleting || mode === "update"} variant="contained"
                             type="submit" endIcon={<SaveIcon/>}>
                Uložit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserEdit;
