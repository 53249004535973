import React, {useContext, useState} from 'react';
import Sidebar from "../components/Sidebar";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Button} from "@mui/material";
import ContractEdit from "../components/popUps/contract/ContractEdit";
import {GlobalDataContext} from "../components/GlobalDataProvider";

export default function Home() {
  const [contractEditOpened, setContractEditOpened] = useState(false);
  const {globalDeleteContract} = useContext(GlobalDataContext);
  const {globalUpdateContract} = useContext(GlobalDataContext);

  const handleAddClick = () => {
    setContractEditOpened(true);
  }

  const handlePopUpClose = () => {
    setContractEditOpened(false);
  }

  return (
    <Sidebar>
      <Typography variant="h5" fontWeight="bold">Home</Typography>
      <Typography variant="h7">NOHO</Typography>
      <br/>
      <Button variant="contained" color="success" onClick={handleAddClick} sx={{ fontSize: "14", padding: 0.5, paddingLeft: 2, paddingRight: 2, mt: 2}} startIcon={<AddCircleIcon/>}>
        Přidat Zakázku
      </Button>
      <ContractEdit mode={"fastCreate"} companyData={null} selectedContract={null} opened={contractEditOpened} close={handlePopUpClose} deleteContract={globalDeleteContract} updateRows={globalUpdateContract} inputDictionaryList={null} />
    </Sidebar>

  );
}
