import React from 'react';
import {useEffect, useState} from 'react';
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {contractsColumns} from "../data/DatatableColumns";
import Typography from "@mui/material/Typography";
import {Box, Button, Paper} from "@mui/material";
import {csCZ} from "@mui/x-data-grid/locales";
import ContractEdit from "./popUps/contract/ContractEdit";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from "@mui/material/Grid2";
import {CustomSearchBar, useFilteredColumns} from "./CustomSearchBar";

const predefinedFilters = [
  {
    label: 'Vše',
    filterModel: { items: [] },
  },
  {
    label: 'F0',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F0' }],
    },
  },
  {
    label: 'F1',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F1' }],
    },
  },
  {
    label: 'F2',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F2' }],
    },
  },
  {
    label: 'F3',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F3' }],
    },
  },
  {
    label: 'F4',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F4' }],
    },
  },
  {
    label: 'F5',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F5' }],
    },
  },
  {
    label: 'F6',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F6' }],
    },
  },
  {
    label: 'F7',
    filterModel: {
      items: [{ field: 'phaseList', operator: 'contains', value: 'F7' }],
    },
  },
];


  function Contracts({data, isCompany, updateOrAddContract,updateUserValue, deleteContract, dictionaryListOptions}) {
    const [selectedFilter, setSelectedFilter] = useState(predefinedFilters[0].label);
    const [rows, setRows] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [CRUDPopUpOpened, setCRUDPopUpOpened] = useState(false);
    const [popUpMode, setPopUpMode] = useState("");

    const apiRef = useGridApiRef();

    useEffect(() => {
      if (data && data.report) generateRows()
    }, [data]);


    const generateRows = async () => {
      let rows = [];

      data.report.contractList.forEach((contract) => {

        let newCostAssumption = 0;
        let newScope = 0;
        let realized = 0;

        contract.userValueList.forEach((userValue) => {
          userValue.businessMonthAssumptionList.forEach((assumption) => {
            newCostAssumption += assumption.value || 0;
          })

          userValue.businessMonthScopeList.forEach((scope) => {
            newScope += scope.value || 0;
          })
        })

        data.report.receivedInvoiceList.forEach((invoice) => {
          if (invoice.contract === contract.code) {
            realized += invoice.actualPayment;
          }
        })
        rows.push({
          id: contract.code,
          code: contract.code,
          name: contract.name,
          status: contract.status,
          contractNumber: contract.contractNumber,
          startDate: contract.startDate,
          endDate: contract.endDate,
          costAssumption: contract.costAssumption,
          scope: contract.scope,
          newCostAssumption: newCostAssumption,
          newScope: newScope,
          realized: realized,
          remaining: newScope - realized,
          costCenter: contract.costCenter,
          groupList: contract.groupList,
          sourceList: contract.sourceList,
          phaseList: contract.phaseList,
          userValueList: contract.userValueList,
          dictionaryItemCompanyCode: contract.dictionaryItemCompanyCode,
          dictionary: dictionaryListOptions.find(dictionary => dictionary.code === contract.dictionaryItemCompanyCode)?.name
        })
        setRows(rows);
      })
    }
  const handleUpdateClick = (idPrefix) => {
    const selectedContract = rows.find(row => row.code === idPrefix);
    if (selectedContract) {
      setPopUpMode("update");
      setSelectedContract(selectedContract);
      handlePopUpOpen();
    } else {
      console.error("No matching contract found");
    }
  };

  const handleAddClick = () => {
    setSelectedContract(null);
    setPopUpMode(isCompany ? "create" : "fastCreate");
    handlePopUpOpen();
  }
  const handleDuplicateClick = (idPrefix) => {
    const selectedContract = rows.find(row => row.code === idPrefix);
    if (selectedContract) {
      setPopUpMode("create");
      setSelectedContract(selectedContract);
      handlePopUpOpen();
    } else {
      console.error("No matching contract found");
    }
    }

  const handlePopUpOpen = () => {
    setCRUDPopUpOpened(true);
  }
  const handlePopUpClose = () => {
    setCRUDPopUpOpened(false);
    setSelectedContract(null);
  }

    const columns = useFilteredColumns(() => contractsColumns(handleUpdateClick,handleDuplicateClick,isCompany));



    return (
    <>
      {predefinedFilters.map(({ label, filterModel }) => {
        return(
          <Button
            key={label}
            onClick={() => {apiRef.current.setFilterModel(filterModel); setSelectedFilter(label)}}
            variant={label === selectedFilter ? "contained" : "outlined"}
            size="small"
            sx={{ margin: 1, marginBottom: 2 }}
          >
            {label}
          </Button>
        );
      })}
    <Paper>
      <Grid container>
        <Grid size={6} >
          <Typography
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 1.7,
              fontWeight: 'bold',
              backgroundColor: 'white',
              position: 'sticky',
              left: 0,
              fontSize: "0.85rem",
              height: '45px',
            }}
          >
            Zakázky
          </Typography>
        </Grid>
        <Grid size={6} alignItems="end" sx={{alignItems: "center", justifyContent: "end", display: "flex", paddingRight: 2}} >
          <Box id="filter-panel" />
          <Button variant="contained" color="success" onClick={handleAddClick} sx={{ fontSize: "14", padding: 0.5, paddingLeft: 2, paddingRight: 2}} startIcon={<AddCircleIcon/>}>
            Přidat
          </Button>
        </Grid>
      </Grid>
      <DataGridPro
        columns={columns}
        rows={rows}
        hideFooter
        apiRef={apiRef}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        getCellClassName={(params) => {
          if (params.value < 0) {
            return 'negative'
          }
        }}
        slots={{ toolbar: CustomSearchBar }}
        searchBar={true}
        sx={{
          height: "68vh",
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', overflow: 'visible'
          },
          '& .negative': {
            color: 'red'
          },
        }}/>
      <ContractEdit mode={popUpMode} companyData={data} updateUserValue={updateUserValue} selectedContract={selectedContract} opened={CRUDPopUpOpened} close={handlePopUpClose} deleteContract={deleteContract} updateRows={updateOrAddContract} inputDictionaryList={dictionaryListOptions} isCostCenter={!isCompany} />
    </Paper>
    </>
  );
}
export default Contracts;