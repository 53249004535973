export const groupsRows = (companyData, months) => {
  const dataTypes = ["outcomes", "incomes", "dph", "investments", "loanBank", "loanGroup"];
  const overallSums = initializeOverallSums(months, dataTypes);
  const totalSums = initializeTotalSums(dataTypes);
  const groupData = aggregateGroupData(companyData, dataTypes, months);

  const validGroupRows = [];
  const specialGroupRows = [];
  const sumRows = [];

  prepareGroupRows(groupData, validGroupRows, specialGroupRows, overallSums, totalSums, months, dataTypes);

  addSumRows(sumRows, overallSums, totalSums, months, dataTypes);
  const cashFlowRow = createCashFlowRow(overallSums, totalSums, months);

  const rows = [...validGroupRows, ...specialGroupRows, ...sumRows];
  sortRows(rows);
  rows.push(cashFlowRow);

  return rows;
};

// Helper Functions
const initializeOverallSums = (months, dataTypes) => {
  const overallSums = {};
  months.forEach(month => {
    overallSums[`assumptions_${month}`] = {};
    overallSums[`spendings_${month}`] = {};
    dataTypes.forEach(type => {
      overallSums[`assumptions_${month}`][type] = 0;
      overallSums[`spendings_${month}`][type] = 0;
    });
  });
  return overallSums;
};

const initializeTotalSums = dataTypes => {
  const totalSums = {};
  dataTypes.forEach(type => {
    totalSums[type] = {
      sumAssumptions: 0,
      sumReality: 0,
      totalAssumptionValues: 0,
      totalValues: 0,
    };
  });
  return totalSums;
};

const aggregateGroupData = (companyData, dataTypes, months) => {
  const groupData = {};
  companyData.forEach(company => {
    const { group, monthlyData, assumptionData, totalValues, totalAssumptionValues, monthlyBalances } = company;
    const groupLabel = resolveGroupLabel(group);

    if (!groupData[groupLabel]) {
      groupData[groupLabel] = createGroupEntry(dataTypes, months);
    }

    aggregateMonthlyData(groupData[groupLabel].monthlyDataByMonth, monthlyData);
    aggregateMonthlyData(groupData[groupLabel].assumptionDataByMonth, assumptionData);

    aggregateTotals(groupData[groupLabel].totalValues, totalValues);
    aggregateTotals(groupData[groupLabel].totalAssumptionValues, totalAssumptionValues);

    if (monthlyBalances) {
      aggregateMonthlyBalances(groupData[groupLabel].monthlyBalances, monthlyBalances, months);
    }
  });
  return groupData;
};

const resolveGroupLabel = group => {
  if (group === undefined) return 'Skupina "undefined"';
  if (group === null) return 'Skupina "null"';
  if (group === '') return 'Ostatní';
  return group;
};

const createGroupEntry = (dataTypes, months) => ({
  monthlyDataByMonth: {},
  assumptionDataByMonth: {},
  totalValues: initializeDataTypes(dataTypes),
  totalAssumptionValues: initializeDataTypes(dataTypes),
  monthlyBalances: initializeMonthlyBalances(months),
});

const initializeDataTypes = dataTypes => {
  const data = {};
  dataTypes.forEach(type => {
    data[type] = 0;
  });
  return data;
};

const initializeMonthlyBalances = months => {
  const balances = {};
  months.forEach(month => {
    balances[month] = 0;
  });
  return balances;
};

const aggregateMonthlyData = (target, source) => {
  Object.entries(source).forEach(([monthYear, data]) => {
    if (!target[monthYear]) target[monthYear] = initializeDataTypes(Object.keys(data));
    Object.keys(data).forEach(key => {
      target[monthYear][key] += data[key];
    });
  });
};

const aggregateMonthlyBalances = (target, source, months) => {
  months.forEach(month => {
    target[month] += source[month] || 0;
  });
};

const aggregateTotals = (target, source) => {
  Object.keys(source).forEach(type => {
    target[type] += source[type];
  });
};

const prepareGroupRows = (groupData, validGroupRows, specialGroupRows, overallSums, totalSums, months, dataTypes) => {
  Object.entries(groupData).forEach(([groupLabel, group]) => {
    dataTypes.forEach(dataType => {
      const row = createRow(groupLabel, dataType, group, months, overallSums, totalSums);
      if (groupLabel.startsWith('Faktury')) {
        specialGroupRows.push(row);
      } else {
        validGroupRows.push(row);
      }
    });

    // Add MonthlyBalances row
    const balanceRow = createMonthlyBalancesRow(groupLabel, group.monthlyBalances, months);
    validGroupRows.push(balanceRow);
  });
};

const createRow = (groupLabel, dataType, group, months, overallSums, totalSums) => {
  const row = {
    id: `${groupLabel}-${dataType}`,
    hierarchy: [`${groupLabel}`, translateDataType(dataType)],
  };

  let sumAssumptions = 0;
  let sumReality = 0;

  months.forEach(month => {
    const assumptionValue = group.assumptionDataByMonth?.[month]?.[dataType] || 0;
    const realityValue = group.monthlyDataByMonth?.[month]?.[dataType] || 0;

    row[`assumptions_${month}`] = assumptionValue;
    row[`spendings_${month}`] = realityValue;

    sumAssumptions += assumptionValue;
    sumReality += realityValue;

    // Update overall sums
    if (!overallSums[`assumptions_${month}`]) overallSums[`assumptions_${month}`] = {};
    if (!overallSums[`spendings_${month}`]) overallSums[`spendings_${month}`] = {};

    overallSums[`assumptions_${month}`][dataType] =
      (overallSums[`assumptions_${month}`][dataType] || 0) + assumptionValue;
    overallSums[`spendings_${month}`][dataType] =
      (overallSums[`spendings_${month}`][dataType] || 0) + realityValue;
  });

  row.sum_assumptions = sumAssumptions;
  row.sum_reality = sumReality;
  row.total_assumption_values = group.totalAssumptionValues?.[dataType] || 0;
  row.total_values = group.totalValues?.[dataType] || 0;

  // Update total sums
  if (!totalSums[dataType]) totalSums[dataType] = { sumAssumptions: 0, sumReality: 0, totalAssumptionValues: 0, totalValues: 0 };

  totalSums[dataType].sumAssumptions += sumAssumptions;
  totalSums[dataType].sumReality += sumReality;
  totalSums[dataType].totalAssumptionValues += row.total_assumption_values;
  totalSums[dataType].totalValues += row.total_values;

  return row;
};

const createMonthlyBalancesRow = (groupLabel, monthlyBalances, months) => {
  const row = {
    id: `${groupLabel}-MonthlyBalances`,
    hierarchy: [`${groupLabel}`],
  };

  let totalBalance = 0;

  months.forEach(month => {
    const balance = monthlyBalances[month] || 0;
    row[month] = balance;
    totalBalance += balance;
  });

  row.sum_balance = totalBalance;

  return row;
};

const addSumRows = (sumRows, overallSums, totalSums, months, dataTypes) => {
  dataTypes.forEach(dataType => {
    const row = { id: `sum-${dataType}`, hierarchy: ["Celkem", translateDataType(dataType)] };
    let totalSumAssumptions = 0;
    let totalSumReality = 0;

    months.forEach(month => {
      row[`assumptions_${month}`] = overallSums[`assumptions_${month}`][dataType];
      row[`spendings_${month}`] = overallSums[`spendings_${month}`][dataType];
      totalSumAssumptions += overallSums[`assumptions_${month}`][dataType];
      totalSumReality += overallSums[`spendings_${month}`][dataType];
    });

    row.sum_assumptions = totalSumAssumptions;
    row.sum_reality = totalSumReality;
    row.total_assumption_values = totalSums[dataType].totalAssumptionValues;
    row.total_values = totalSums[dataType].totalValues;

    sumRows.push(row);
  });
};

const createCashFlowRow = (overallSums, totalSums, months) => {
  const row = { id: "cashFlow", hierarchy: ["Čistý finanční tok"] };
  let totalCashFlowAssumptions = 0;
  let totalCashFlowReality = 0;

  months.forEach(month => {
    const incomesAssumptions = overallSums[`assumptions_${month}`].incomes || 0;
    const outcomesAssumptions = overallSums[`assumptions_${month}`].outcomes || 0;
    const incomesReality = overallSums[`spendings_${month}`].incomes || 0;
    const outcomesReality = overallSums[`spendings_${month}`].outcomes || 0;

    const cashFlowAssumptions = incomesAssumptions + outcomesAssumptions;
    const cashFlowReality = incomesReality + outcomesReality;

    row[`assumptions_${month}`] = cashFlowAssumptions;
    row[`spendings_${month}`] = cashFlowReality;

    totalCashFlowAssumptions += cashFlowAssumptions;
    totalCashFlowReality += cashFlowReality;
  });

  row.sum_assumptions = totalCashFlowAssumptions;
  row.sum_reality = totalCashFlowReality;
  row.total_assumption_values = totalSums.incomes.totalAssumptionValues + totalSums.outcomes.totalAssumptionValues;
  row.total_values = totalSums.incomes.totalValues + totalSums.outcomes.totalValues;

  return row;
};

const sortRows = rows => {
  rows.sort((a, b) => {
    if (a.hierarchy[0] === "Celkem") return 1;
    if (b.hierarchy[0] === "Celkem") return -1;
    return a.hierarchy[0]?.localeCompare(b.hierarchy[0] || "") || 0;
  });
};

const dataTypeTranslations = {
  incomes: "Příjmy",
  outcomes: "Výdaje",
  dph: "DPH",
  investments: "Investice",
  sale: "Prodej",
  rent: "Pronájem",
  loanBank: "Půjčka - banka",
  loanGroup: "Půjčka - skupina"
};

// Function to translate data types to Czech
function translateDataType(dataType) {
  return dataTypeTranslations[dataType] || dataType;
}