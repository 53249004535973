import React, { useEffect, useState} from 'react';
import EntityDetail from "../../components/EntityDetail";
import Sidebar from "../../components/Sidebar";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../api/Api";


const CompanyDetail = () => {
  const [company, setCompany] = useState(null);
  const {id} = useParams();
  const navigate = useNavigate();

  const fetchCompany = async (id) => {
    try {
      const response = await api.post(
          `/companyReport/get`,
        {companyCode: id},
      );
      setCompany({name: response.data.report.companyDetail.name, data: response.data});
    } catch (error) {
      console.error(`Error fetching detailed data:`, error);
      if (error.response?.status === 401) navigate("/");
    }
  };

  useEffect(() => {
    id && fetchCompany(id);
  }, [id]);

  return (
    <Sidebar>
        <EntityDetail
          entity={company && company.data ? company : null}
          entityName="Firmy"
          apiPath="/company"
          navPath="/companies"
          isCompany={true}
        />
    </Sidebar>
  );
};

export default CompanyDetail;