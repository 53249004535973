import React, {useContext, useEffect, useState} from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BudgePopUP from "./popUps/budgetTable/BudgePopUP";
import Grid from '@mui/material/Grid2';
import {toast} from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import {toastWithError} from "./ToastWithError";
import {GlobalDataContext} from "./GlobalDataProvider";
import {formatAmount} from "../utils/Formater";
import {api} from "../api/Api";
import {useNavigate} from "react-router-dom";

const labels = [{
  "code": "F0", "name": "F0 - Nákup"
}, {
  "code": "F1", "name": "F1 - Projekční práce"
}, {
  "code": "F2", "name": "F2 - Stavba"
}, {
  "code": "F3", "name": "F3 - Projektové řízení"
}, {
  "code": "F4", "name": "F4 - Marketing a prodej"
}, {
  "code": "F5", "name": "F5 - Pozáruční servis"
}, {
  "code": "F6", "name": "F6 - Firma"
}, {
  "code": "F7", "name": "F7 - Finanční náklady"
},];

const calculateReceivedInvoiceSum = (data, label) => {
  return data.reduce((sum, invoice) => {
    if (invoice.phaseList.includes(label) || invoice?.sourceList.includes(label)) {
      sum += invoice.amountBase || 0;
    }
    return sum;
  }, 0);
};


const BudgetTable = ({data, title, id}) => {
  const {setUpdateMessage} = useContext(GlobalDataContext);
  const {getUpdatedDataTime} = useContext(GlobalDataContext);
  const [volumeStudyValues, setVolumeStudyValues] = useState([]);
  const [designBudgetValues, setDesignBudgetValues] = useState([]);
  const [popUpOpened, setPopUpOpened] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const [popUpData, setPopUpData] = useState({
    volumeStudyValues: [], designBudgetValues: []
  });
  const contracts = data.report.contractList;
  const navigate = useNavigate();

  const rows = labels.map(label => {
    const labelCode = label.code;

    const scopePerMonth = contracts.reduce((sum, contract) => {
      const userValue = contract.userValueList.find(u => u.label === labelCode);
      return sum + (userValue ? userValue.businessMonthScopeList.reduce((acc, v) => acc + v.value, 0) : 0);
    }, 0);

    const assumptionPerMonth = contracts.reduce((sum, contract) => {
      const userValue = contract.userValueList.find(u => u.label === labelCode);
      return sum + (userValue ? userValue.businessMonthAssumptionList.reduce((acc, v) => acc + v.value, 0) : 0);
    }, 0);

    const factured = calculateReceivedInvoiceSum(data.report.receivedInvoiceList, labelCode);

    const volumeStudyValue = volumeStudyValues[labels.indexOf(label)] || 0;
    const designBudgetValue = designBudgetValues[labels.indexOf(label)] || 0;

    return {
      ...label,
      scopePerMonth,
      assumptionPerMonth,
      factured,
      volumeStudyValue,
      designBudgetValue
    };
  });

  const updateValueList = async (volumeStudyValues, designBudgetValues) => {
    try {
      const updatingDataset = await api.get(`/dataset/get`);
      if (updatingDataset.data.updating) {
        toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
        setUpdateMessage(updatingDataset.data.message);
        getUpdatedDataTime();
        return
      }
      if (data.report?.costCenterDetail) {
        await api.post("/costCenter/update", {
          costCenterCode: id, timeInterval: {
            from: data.report.costCenterDetail.timeInterval.from, to: data.report.costCenterDetail.timeInterval.to
          }, costVolumeStudyList: volumeStudyValues, costDesignBudgetList: designBudgetValues
        });
      } else if (data.report?.companyDetail) {
        await api.post("/company/update", {
          companyCode: id, timeInterval: {
            from: data.report.companyDetail.timeInterval.from, to: data.report.companyDetail.timeInterval.to
          }, costVolumeStudyList: volumeStudyValues, costDesignBudgetList: designBudgetValues
        });
      }
      setVolumeStudyValues(popUpData.volumeStudyValues);
      setDesignBudgetValues(popUpData.designBudgetValues);
      toast.success('Hodnoty aktualizovány');
      handlePopUpClose();
    } catch (error) {
      console.error('Error updating time interval:', error);
      if (error.response?.status === 401) {
        navigate("/");
        return;
      }
      toastWithError(error);
      toast.error('Hodnoty se nepodařilo aktualizovat');
    }
  };

  const sumReducer = (acc, row) => {
    return {
      scopePerMonth: acc.scopePerMonth + row.scopePerMonth,
      assumptionPerMonth: acc.assumptionPerMonth + row.assumptionPerMonth,
      factured: acc.factured + row.factured,
      volumeStudyValue: acc.volumeStudyValue + row.volumeStudyValue,
      designBudgetValue: acc.designBudgetValue + row.designBudgetValue,
    };
  };

  const sumRow = rows.reduce(sumReducer, {
    scopePerMonth: 0, assumptionPerMonth: 0, factured: 0, volumeStudyValue: 0, designBudgetValue: 0,
  });

  const handlePopUpOpen = () => {
    setPopUpData({
      volumeStudyValues: [...volumeStudyValues], designBudgetValues: [...designBudgetValues]
    });
    setPopUpOpened(true);
  };

  const handlePopUpClose = () => {
    setPopUpOpened(false);
  };

  const handlePopUpSave = () => {
    updateValueList(popUpData.volumeStudyValues, popUpData.designBudgetValues);
  };

  const handlePopUpChange = (index, valueType, value) => {
    const newValue = parseFloat(value) || 0;
    setPopUpData((prevData) => {
      const newData = {...prevData};
      if (valueType === 'volumeStudy') {
        newData.volumeStudyValues[index] = newValue;
      } else {
        newData.designBudgetValues[index] = newValue;
      }
      return newData;
    });
  };

  const handleClearVolumeStudy = () => {
    const clearedValues = volumeStudyValues.map(() => 0);
    setVolumeStudyValues(clearedValues);

    setPopUpData((prevData) => ({
      ...prevData, volumeStudyValues: clearedValues,
    }));
    setRenderKey(prevKey => prevKey + 1);

  };

  const handleClearDesignBudget = () => {
    const clearedValues = designBudgetValues.map(() => 0);
    setDesignBudgetValues(clearedValues);

    setPopUpData((prevData) => ({
      ...prevData, designBudgetValues: clearedValues,
    }));
    setRenderKey(prevKey => prevKey + 1);

  };

  useEffect(() => {
    if (data && data.report?.companyDetail) {
      setVolumeStudyValues(data.report.companyDetail.costVolumeStudyList);
      setDesignBudgetValues(data.report.companyDetail.costDesignBudgetList);
    }
    if (data && data.report?.costCenterDetail) {
      setVolumeStudyValues(data.report.costCenterDetail.costVolumeStudyList);
      setDesignBudgetValues(data.report.costCenterDetail.costDesignBudgetList);
    }
  }, []);

  return (<TableContainer component={Paper}>
    <Typography
      sx={{
        paddingLeft: 2,
        paddingTop: 1.7,
        paddingBottom: 2,
        fontWeight: 'bold',
        borderBottom: '1px solid #ccc',
        height: '45px'
      }}>
      {title}
    </Typography>
    <Table stickyHeader>
      <TableHead>
        <TableRow sx={{backgroundColor: '#f5f5f5'}}>
          <TableCell
            sx={{minWidth: 180, fontWeight: 'bold', borderBottom: '1px solid #ccc', paddingLeft: "16px"}}>Fáze
            projektu</TableCell>
          <TableCell align="right" sx={{fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: "0px"}}>
            <div style={{alignItems: 'center', display: 'inline-flex'}}>
              Objemová studie
              <EditIcon sx={{marginRight: 1, marginLeft: 1, cursor: 'pointer', fontSize: "1.2rem"}}
                        onClick={handlePopUpOpen}/>
            </div>
          </TableCell>
          <TableCell align="right" sx={{fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: "0px"}}>
            <div style={{alignItems: 'center', display: 'inline-flex'}}>
              Projektový rozpočet
              <EditIcon sx={{marginRight: 1, marginLeft: 1, cursor: 'pointer', fontSize: "1.2rem"}}
                        onClick={handlePopUpOpen}/>
            </div>
          </TableCell>
          <TableCell align="right"
                     sx={{fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: "0px"}}>Plán</TableCell>
          <TableCell align="right"
                     sx={{fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: "0px"}}>Smlouva</TableCell>
          <TableCell align="right" sx={{
            fontWeight: 'bold', borderBottom: '1px solid #ccc', padding: "10px, 0px, 10px, 0px"
          }}>Vyfakturováno</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={row.code} sx={{backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff'}}>
            <TableCell sx={{padding: "6px 0px 6px 15px"}}>{row.name}</TableCell>
            <TableCell align="right"
                       sx={{padding: "6px 0px 6px 15px", color: row.volumeStudyValue >= 0 ? 'inherit' : 'red'}}>
              {formatAmount(row.volumeStudyValue)}
            </TableCell>
            <TableCell align="right" sx={{padding: "0px", color: row.designBudgetValue >= 0 ? 'inherit' : 'red'}}>
              {formatAmount(row.designBudgetValue)}
            </TableCell>
            <TableCell
              align="right"
              sx={{padding: "0px", color: row.designBudgetValue >= 0 ? 'inherit' : 'red'}}
            >
                  {formatAmount(row.assumptionPerMonth)}
            </TableCell>
            <TableCell align="right" sx={{padding: "0px", color: row.designBudgetValue >= 0 ? 'inherit' : 'red'}}>
             {formatAmount(row.scopePerMonth)}
            </TableCell>
            <TableCell align="right" sx={{
              color: row.factured >= 0 ? 'inherit' : 'red', marginRight: "20px"
            }}>{formatAmount(row.factured)}</TableCell>
          </TableRow>))}
        <TableRow sx={{backgroundColor: '#eeeeee'}}>
          <TableCell sx={{fontWeight: 'bold', padding: '8px 16px'}}>{"Celkem"}</TableCell>
          <TableCell align="right"
                     sx={{color: sumRow.volumeStudyValue >= 0 ? 'inherit' : 'red', fontWeight: 'bold', padding: "0px"}}>
            {formatAmount(sumRow.volumeStudyValue)}
          </TableCell>
          <TableCell align="right" sx={{
            color: sumRow.designBudgetValue >= 0 ? 'inherit' : 'red', fontWeight: 'bold', padding: "0px"
          }}>
            {formatAmount(sumRow.designBudgetValue)}
          </TableCell>
          <TableCell align="right" sx={{
            color: sumRow.assumptionPerMonth >= 0 ? 'inherit' : 'red', fontWeight: 'bold', padding: "0px"
          }}>{formatAmount(sumRow.assumptionPerMonth)}</TableCell>
          <TableCell align="right" sx={{
            color: sumRow.scopePerMonth >= 0 ? 'inherit' : 'red', fontWeight: 'bold', padding: "0px"
          }}>{formatAmount(sumRow.scopePerMonth)}</TableCell>
          <TableCell align="right"
                     sx={{
                       color: sumRow.factured >= 0 ? 'inherit' : 'red', fontWeight: 'bold', marginRight: "20px"
                     }}>{formatAmount(sumRow.factured)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <BudgePopUP
      handleOpen={handlePopUpOpen}
      handleClose={handlePopUpClose}
      handleSave={handlePopUpSave}
      opened={popUpOpened}
    >
      <Box>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid size={4}>
            <Typography variant="body1" fontWeight="bold">Fáze</Typography>
          </Grid>
          <Grid size={4}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body1" fontWeight="bold">Objemová studie</Typography>
              <DeleteIcon onClick={() => handleClearVolumeStudy()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>
          <Grid size={4}>
            <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
              <Typography variant="body1" fontWeight="bold">Projektový rozpočet</Typography>
              <DeleteIcon onClick={() => handleClearDesignBudget()}
                          sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
            </div>
          </Grid>

          {rows.map((row, index) => (<React.Fragment key={row.code}>
            <Grid size={4}>
              <Typography variant="body2" sx={{display: 'flex', alignItems: 'center'}}>{row.name}</Typography>
            </Grid>
            <Grid size={4}>
              <Box key={renderKey}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  defaultValue={row.volumeStudyValue}
                  onChange={(e) => handlePopUpChange(index, 'volumeStudy', e.target.value)}
                />
              </Box>
            </Grid>
            <Grid size={4}>
              <Box key={renderKey}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  defaultValue={row.designBudgetValue}
                  onChange={(e) => handlePopUpChange(index, 'designBudget', e.target.value)}
                />
              </Box>
            </Grid>
          </React.Fragment>))}
        </Grid>
      </Box>
    </BudgePopUP>
  </TableContainer>);
};

export default BudgetTable;
