import axios from "axios";
import { toast } from "react-hot-toast";

const API_BASE_URL = process.env.REACT_APP_FLEXIOVERVIEW_API_URL;
export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

let isAuthToastShown = false; // Prevent multiple toasts

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const expireAt = localStorage.getItem("expireAt");

    if (config.url !== "/application/login" && (!token || !expireAt || new Date(expireAt) < new Date())) {
      localStorage.removeItem("token");
      localStorage.removeItem("expireAt");

      return Promise.reject({ response: { status: 401, message: "Token expired or missing" } });
    }

    config.headers["Authorization"] = token;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("expireAt");

      if (!isAuthToastShown) {
        isAuthToastShown = true;
        toast.error("Vaše přihlášení vypršelo.");
        setTimeout(() => { isAuthToastShown = false; }, 5000); // Reset flag after 5s
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

/**
 * Sets the authorization token and expiration time for future requests.
 * @param {string} token - The authorization token.
 * @param {string} expireAt - The expiration date as an ISO string.
 */
export const setAuthToken = (token, expireAt) => {
  if (token && expireAt) {
    api.defaults.headers.common["Authorization"] = token;
    localStorage.setItem("token", token);
    localStorage.setItem("expireAt", expireAt);
  } else {
    delete api.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
    localStorage.removeItem("expireAt");
  }
};
