import {GridToolbarContainer } from "@mui/x-data-grid-pro";
import React from "react";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

// Funkce pro odstranění diakritiky a mezer
const removeDiacritics = (str) => {
  return str
    .normalize("NFD") // Rozloží písmena s diakritikou (ě -> e + ˇ)
    .replace(/[\u0300-\u036f]/g, "") // Odstraní diakritiku
    .replace(/\s/g, "") // Odstraní mezery
    .toLowerCase(); // Převede na malá písmena
};

// Obecný filtr ignorující mezery i diakritiku
const defaultFilter = (filterValue) => {
  return (cellValue) => {
    if (!cellValue || !filterValue) return false;
    return removeDiacritics(cellValue.toString()).includes(removeDiacritics(filterValue));
  };
};

// Filtr pro datum (také ignoruje mezery i diakritiku)
const dateFilter = (filterValue) => {
  return (cellValue) => {
    if (!cellValue || !filterValue) return false;

    const formattedCellDate = removeDiacritics(
      new Intl.DateTimeFormat('cs-CZ').format(new Date(cellValue))
    );

    return formattedCellDate.includes(removeDiacritics(filterValue));
  };
};

// Použití filtrů na sloupce
export const useFilteredColumns = (getColumns) => {
  return React.useMemo(
    () =>
      getColumns().map((column) => {
        if (column.type === 'date') {
          return {
            ...column,
            getApplyQuickFilterFn: dateFilter, // Použití filtru pro datum
          };
        } else {
          return {
            ...column,
            getApplyQuickFilterFn: defaultFilter, // Použití filtru ignorujícího mezery i diakritiku
          };
        }
      }),
    [getColumns]
  );
};

export function CustomSearchBar() {
  return (
    <GridToolbarContainer sx={{ borderBottom: "1px solid #E0E0E0", p: 0.5 }}>
      <GridToolbarQuickFilter sx={{ ml: 1 }} />
    </GridToolbarContainer>
  );
}