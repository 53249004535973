import React, {useState} from "react";
import {Paper, TextField, Button, Box, Typography} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import toast from 'react-hot-toast';
import {useAuth} from "../auth/AuthProvider";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (username && password) {auth.loginAction(username, password);
      } else {
        toast.error('Přihlášení se nezdařilo')
      }
    } catch (error) {
      toast.error('Přihlášení se nezdařilo')
      console.error(error);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Paper elevation={15} style={{paddingTop: 70, paddingBottom: 70, padding: 40}}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            color: '#3a3a3a'
          }}
        >
          Přihlášení
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'flex-end', paddingTop: 3, fontSize: 26}}>
          <AccountCircle sx={{color: '#3a3a3a', mr: 1, my: 0.5}}/>
          <TextField
            id="input-with-sx"
            label="Přihlašovací jméno"
            variant="standard"
            sx={{width: "85%", fontSize: 26}}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'flex-end', paddingTop: 2}}>
          <LockIcon sx={{color: '#3a3a3a', mr: 1, my: 0.5}}/>
          <TextField
            id="input-with-sx"
            label="Heslo"
            type='password'
            variant="standard"
            sx={{width: "85%"}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 5}}>
          <Button type='submit' color='primary' variant="contained" sx={{
            paddingLeft: 5,
            paddingRight: 5,
          }}>
            Přihlásit se
          </Button>
        </Box>
      </Paper>
    </form>
  );
}

export default LoginForm;