import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/LoginPage";
import { GlobalDataProvider } from "./components/GlobalDataProvider";
import Home from "./pages/Home";
import { LicenseInfo } from "@mui/x-license";
import AuthProvider from "./auth/AuthProvider";
import PrivateRoute from "./auth/PrivateRoute";
import Users from "./pages/Users";
import Tasks from "./pages/Tasks";
import Activities from "./pages/Activities";
import Groups from "./pages/Groups";
import Centres from "./pages/Centre/Centres";
import CentreDetail from "./pages/Centre/CentreDetail";
import Companies from "./pages/Company/Companies";
import CompanyDetail from "./pages/Company/CompanyDetail"; // Import your AuthProvider

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const ROUTES = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/users",
    component: <Users />,
    requiredRole: "admin",
  },
  {
    path: "/tasks",
    component: <Tasks />,
    requiredRole: "admin",
  },
  {
    path: "/activities",
    component: <Activities />,
    requiredVisibility: "Činnosti",
  },
  {
    path: "/groups",
    component: <Groups />,
    requiredVisibility: "Skupina",
  },
  {
    path: "/centres",
    component: <Centres />,
    requiredVisibility: "Střediska",
  },
  {
    path: "/centre/:id",
    component: <CentreDetail />,
    requiredVisibility: "Střediska",
  },
  {
    path: "/companies",
    component: <Companies />,
    requiredVisibility: "Firmy",
  },
  {
    path: "/company/:id",
    component: <CompanyDetail />,
    requiredVisibility: "Firmy",
  }
]

const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      {ROUTES.map((route) => (
        <Route element={<PrivateRoute requiredRole={route.requiredRole} requiredVisibility={route.requiredVisibility} />}>
          <Route path={route.path} element={route.component} />
        </Route>
      ))}
    </Routes>
  );
};


function App() {
  return (
    <Router>
    <AuthProvider>
        <GlobalDataProvider>
          <AppRoutes />
          <Toaster position="top-right" reverseOrder={false} />
        </GlobalDataProvider>
    </AuthProvider>
    </Router>
  );
}

export default App;