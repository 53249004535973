import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import {
  DataGridPro,
  gridClasses, useGridApiRef,
} from "@mui/x-data-grid-pro";
import {csCZ} from "@mui/x-data-grid/locales";
import Typography from "@mui/material/Typography";
import {cashflowColumns} from "../data/DatatableColumns";
import {convertDateToMMYY} from "../utils/Coverter";
import {generateHiddenColumns} from "../utils/ValueGenerator";

const columnGroupingModel = (months) => [
  ...months.map((month) => ({
    groupId: month,
    headerName: month.slice(0, 2) + "/" + month.slice(2),
    headerAlign: "center",
    headerClassName: `spendings`,
    children: [
      {field: `spendings_${month}`, headerName: "Spendings"},
      {field: `assumptions_${month}`, headerName: "Assumptions"},
    ],
  })),
  {
    groupId: 'sum',
    headerName: 'Suma za období',
    headerAlign: 'center',
    headerClassName: `spendings`,
    children: [
      {field: 'sum', headerName: 'sum'},
      {field: 'sumAssumption', headerName: 'Total Assumption'},
    ],
  },
  {
    groupId: 'total',
    headerName: 'Suma celkem',
    headerAlign: 'center',
    children: [
      {field: 'total', headerName: 'sum'},
      {field: 'totalAssumption', headerName: 'Total Assumption'},
    ],
  },
];
const generateRows = (data, months, rowDefinitions) => {

  // Check if "Total" is already in rowDefinitions
  const totalRowExists = rowDefinitions.some(row => row.code === "Total");
  if (!totalRowExists) {
    rowDefinitions.push({code: "Total", name: "Celkem"});
  }

  const numMonths = months.length;
  const spendings = rowDefinitions.map(() => Array(numMonths).fill(0));
  const assumptions = rowDefinitions.map(() => Array(numMonths).fill(0));

  // Initialize total and totalAssumption arrays for each rowDefinition
  const total = rowDefinitions.map(() => 0);
  const totalAssumption = rowDefinitions.map(() => 0);
    // Process receivedInvoiceList with multiple payments
    data.report.receivedInvoiceList.forEach(invoice => {
      invoice.paymentList.forEach(payment => {
        const monthYear = convertDateToMMYY(payment.paymentDate);
        const monthIndex = months.indexOf(monthYear);
        const rowIndex = rowDefinitions.findIndex(row =>
          invoice.phaseList.includes(row.code) || invoice.groupList.includes(row.code) || invoice.sourceList.includes(row.code)
        );
        const actualPayment = payment.paymentAmount || 0;

        if (rowIndex !== -1 && monthIndex !== -1) {
          spendings[rowIndex][monthIndex] -= actualPayment;
          spendings[rowDefinitions.findIndex(row => row.code === "Total")][monthIndex] -= invoice.sourceList.includes("DPH") ? 0 : actualPayment;
        }
        // Accumulate the total actual payments for this row
        total[rowIndex] -= actualPayment;
        total[rowDefinitions.findIndex(row => row.code === "Total")] -= invoice.sourceList.includes("DPH") ? 0 : actualPayment;
      });
    });

    // Process contractList
    rowDefinitions.forEach((rowDef, rowIndex) => {
      data.report.contractList.forEach(contract => {
        contract.userValueList.forEach(userValue => {
          if (userValue.label === rowDef.code) {
            userValue.businessMonthAssumptionList.forEach(assumptionEntry => {
              const monthIndex = months.indexOf(assumptionEntry.businessMonth);
              const value = assumptionEntry.value;
              if (monthIndex !== -1) {
                assumptions[rowIndex][monthIndex] -= value;
                assumptions[rowDefinitions.findIndex(row => row.code === "Total")][monthIndex] -= value;
              }
              // Accumulate the total assumptions for this row
              totalAssumption[rowIndex] -= value;
              totalAssumption[rowDefinitions.findIndex(row => row.code === "Total")] -= value;
            });
          }
        });
      });
    });
  // Return rows with total and totalAssumption
  return rowDefinitions.map((rowDef, index) => {
    const sum = spendings[index].reduce((sum, value) => sum + value, 0);
    const sumAssumption = assumptions[index].reduce((sum, value) => sum + value, 0);
    return {
      id: index,
      label: rowDef.name,
      spendings: spendings[index],
      assumptions: assumptions[index],
      sum,
      sumAssumption,
      total: total[index],
      totalAssumption: totalAssumption[index]
    };
  });
};

const CashflowOutcomes = ({data, months, rowDefinitions, reference, onRowsChange, handleLabelClick}) => {
  const [rows, setRows] = useState(generateRows(data, months, rowDefinitions));
  const [hiddenColumns, setHiddenColumns] = useState({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    setRows(generateRows(data, months, rowDefinitions));
    const newHiddenColumns = generateHiddenColumns(months);
    setHiddenColumns(newHiddenColumns);

    if (apiRef.current) {
      apiRef.current.setState((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          columnVisibilityModel: newHiddenColumns,
        },
      }));
      apiRef.current.forceUpdate();
    }

    onRowsChange && onRowsChange();
  }, [months, data, rowDefinitions, onRowsChange]);

  const pinnedRows = {
    bottom: [rows[rows.length - 1]],
  };

  return (
    data.report && months.length > 0 && rowDefinitions.length > 0 &&
    <Paper>
      <Typography
        sx={{
          paddingLeft: 2,
          paddingTop: 1.7,
          paddingBottom: 1.7,
          fontWeight: 'bold',
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          fontSize: "0.85rem",
          height: '45px',
        }}
      >
        Výdaje dle fáze
      </Typography>
      <DataGridPro
        apiRef={apiRef}
        ref={reference}
        rows={rows}
        columnHeaderHeight={45}
        hideFooter
        disableRowSelectionOnClick
        disableColumnReorder
        columns={cashflowColumns(months, handleLabelClick)}
        columnGroupingModel={columnGroupingModel(months)}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pinnedColumns: {
            left: ['label'],
            right: ['sumAssumption', 'sum', 'totalAssumption', 'total'],
          },
          columns: {
            columnVisibilityModel: {
              ...hiddenColumns,
            },
          }
        }}
        disableSelectionOnClick
        pinnedRows={pinnedRows}
        getCellClassName={(params) => {
          let classNames = '';

          if (params.value < 0) {
            classNames += 'negative ';
          }

          if (params.rowNode.type === 'pinnedRow') {
            if (params.colDef.headerName.includes('Skutečnost')) {
              classNames += 'pinned-row_spendings ';
            } else {
              classNames += 'pinned-row ';
            }
          }
            classNames += 'spendings ';


          return classNames.trim();
        }}
        sx={{
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            overflow: 'visible',
          },
          '& .MuiDataGrid-columnHeader': { borderRight: '1px solid rgba(224, 224, 224, 1)' },
          '& .spendings': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          [`.${gridClasses.cell}.pinned-row`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
          },
          [`.${gridClasses.cell}.pinned-row_spendings`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-cell.negative': {
            color: 'red',
          },
        }}
      />
    </Paper>
  );
};

export default CashflowOutcomes;