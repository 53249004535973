import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import {GlobalDataContext} from "../components/GlobalDataProvider";

const PrivateRoute = ({ requiredRole, requiredVisibility }) => {
  const { token } = useAuth();
  const { userDetails } = useContext(GlobalDataContext);

  if (!token) {
    return <Navigate to="/" />;
  }

  if (requiredRole && userDetails && userDetails?.role === "user") {
    return <Navigate to="/home" />;
  }

  if (requiredVisibility && userDetails && !userDetails?.systemVisibilityList?.includes(requiredVisibility)) {
    return <Navigate to="/tttt" />;
  }

  return <Outlet />;
};

export default PrivateRoute;