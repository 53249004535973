import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {api, setAuthToken} from "../api/Api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("userId") || "");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [expireAt, setExpireAt] = useState(localStorage.getItem("expireAt") || "");
  const  [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      if (!token || !expireAt) {
        logOut();
      }
      const expirationTime = new Date(expireAt).getTime();
      const currentTime = Date.now();
      const timeout = expirationTime - currentTime;

      if (timeout <= 0) {
        logOut();
      } else {
        console.log(timeout);
        const timer = setTimeout(() => logOut("Vaše přihlášení vypršelo!", true), timeout);
        return () => clearTimeout(timer);
      }
    }
  }, [authenticated, expireAt, token]);

  const loginAction = async (username, password) => {
    try {
      const res = await api.post(`/application/login`, {
        username,
        password
      });

      if (res.data) {
        const { userId, token, expirationAt } = res.data.session;
        const bearerToken = `Bearer ${token}`;

        // Store credentials in localStorage
        localStorage.setItem("token", bearerToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("expireAt", expirationAt);

        setUser(userId);
        setToken(bearerToken);
        setExpireAt(expirationAt);

        // Set token globally for API requests
        setAuthToken(bearerToken, expirationAt);
        setAuthenticated(true);

        toast.success("Byl jste úspěšně přihlášen!");
        navigate("/home");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error("Login error:", err.response?.data || err.message);
      toast.error("Přihlášení selhalo!");
    }
  };

  const logOut = (message = "Byl jste úspěšně odhlášen!", error = false) => {
    setUser(null);
    setToken("");
    setExpireAt("");

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("expireAt");

    // Remove token from API headers
    setAuthToken(null);
    setAuthenticated(false);

    if (error){
      toast.error(message);
    }else {
      toast.success(message);
    }
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};