import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import {
  DataGridPro,
  gridClasses, useGridApiRef,
} from "@mui/x-data-grid-pro";
import {csCZ} from "@mui/x-data-grid/locales";
import Typography from "@mui/material/Typography";
import {cashflowColumns} from "../data/DatatableColumns";
import {convertDateToMMYY} from "../utils/Coverter";
import {generateHiddenColumns} from "../utils/ValueGenerator";

const columnGroupingModel = (months) => [
  ...months.map((month) => ({
    groupId: month,
    headerName: month.slice(0, 2) + "/" + month.slice(2),
    headerAlign: "center",
    headerClassName: `spendings`,
    children: [
      {field: `spendings_${month}`, headerName: "Spendings"},
      {field: `assumptions_${month}`, headerName: "Assumptions"},
    ],
  })),
  {
    groupId: 'sum',
    headerName: 'Suma za období',
    headerAlign: 'center',
    headerClassName: `spendings`,
    children: [
      {field: 'sum', headerName: 'sum'},
      {field: 'sumAssumption', headerName: 'Total Assumption'},
    ],
  },
  {
    groupId: 'total',
    headerName: 'Suma celkem',
    headerAlign: 'center',
    children: [
      {field: 'total', headerName: 'sum'},
      {field: 'totalAssumption', headerName: 'Total Assumption'},
    ],
  },
];

const generateRows = (data, months, rowDefinitions) => {

  // Check if "Total" is already in rowDefinitions
  const totalRowExists = rowDefinitions.some(row => row.code === "Total");
  if (!totalRowExists) {
    rowDefinitions.push({code: "Total", name: "Celkem"});
  }
  const dphImpactRowExists = rowDefinitions.some(row => row.code === "dphImpact");
  if (!dphImpactRowExists) {
    rowDefinitions.push({code: "dphImpact", name: "Vliv DPH"});
  }
  const cashflowRowExists = rowDefinitions.some(row => row.code === "cashflow");
  if (!cashflowRowExists) {
    rowDefinitions.push({code: "cashflow", name: "Čistý finanční tok"});
  }

  const numMonths = months.length;
  const spendings = rowDefinitions.map(() => Array(numMonths).fill(0));
  const assumptions = rowDefinitions.map(() => Array(numMonths).fill(0));

  // Initialize total and totalAssumption arrays for each rowDefinition
  const total = rowDefinitions.map(() => 0);
  const totalAssumption = rowDefinitions.map(() => 0);

  data.report.receivedInvoiceList.forEach(invoice => {
    invoice.paymentList.forEach(payment => {
      const monthYear = convertDateToMMYY(payment.paymentDate);
      const monthIndex = months.indexOf(monthYear);
      if (monthIndex !== -1) {
        spendings[rowDefinitions.findIndex(row => row.code === "cashflow")][monthIndex] -= invoice.sourceList.includes("DPH") ? 0 : payment.paymentAmount;
        spendings[rowDefinitions.findIndex(row => row.code === "dphImpact")][monthIndex] -= invoice.sourceList.includes("DPH") ? payment.paymentAmount : 0;
      }
      total[rowDefinitions.findIndex(row => row.code === "cashflow")] -= invoice.sourceList.includes("DPH") ? 0 : payment.paymentAmount;
      total[rowDefinitions.findIndex(row => row.code === "dphImpact")]-= invoice.sourceList.includes("DPH") ? payment.paymentAmount : 0;

    });
    const dphMonthYear = convertDateToMMYY(invoice.supplyDate);
    const dphMonthIndex = months.indexOf(dphMonthYear);
    if (dphMonthIndex !== -1) {
      assumptions[rowDefinitions.findIndex(row => row.code === "dphImpact")][dphMonthIndex] += invoice.amountVat;
    }
    totalAssumption[rowDefinitions.findIndex(row => row.code === "dphImpact")] += invoice.amountVat;
  });

  data.report.contractList.forEach(contract => {
    contract.userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        const monthYear = assumption.businessMonth;
        const monthIndex = months.indexOf(monthYear);
        if (monthIndex !== -1) {
          assumptions[rowDefinitions.findIndex(row => row.code === "cashflow")][monthIndex] -= assumption.value;
        }
        totalAssumption[rowDefinitions.findIndex(row => row.code === "cashflow")] -= assumption.value;
      });
    });
  });

  data.report.claimList.forEach(claim => {
    const rowIndex = rowDefinitions.findIndex(row => claim.phaseList.includes(row.code) || claim.sourceList.includes(row.code));
    const totalRowIndex = rowDefinitions.findIndex(row => row.code === "Total");
    const dphImpactRowIndex = rowDefinitions.findIndex(row => row.code === "dphImpact");
    const cashflowRowIndex = rowDefinitions.findIndex(row => row.code === "cashflow");

    // Process payments for spendings and totals
    claim.paymentList.forEach(payment => {
      const monthYear = convertDateToMMYY(payment.paymentDate);
      const monthIndex = months.indexOf(monthYear);
      const actualPayment = payment.paymentAmount || 0;

      if (monthIndex !== -1 ) {
        // Update spendings for row-specific, Total, cashflow, and dphImpact
        if (rowIndex !== -1) {
          spendings[rowIndex][monthIndex] += actualPayment;
        }
        spendings[totalRowIndex][monthIndex] += claim.sourceList.includes("DPH") ? 0 : actualPayment;

        // Update cashflow spendings separately to prevent interference with other rows
        spendings[cashflowRowIndex][monthIndex] += claim.sourceList.includes("DPH") ? 0 : actualPayment;

        // Update DPH-specific row
        spendings[dphImpactRowIndex][monthIndex] += claim.sourceList.includes("DPH") ? actualPayment : 0;
      }

      // Accumulate totals for this row and for cashflow independently
      total[rowIndex] += actualPayment;
      total[totalRowIndex] += claim.sourceList.includes("DPH") ? 0 : actualPayment;
      total[cashflowRowIndex] += claim.sourceList.includes("DPH") ? 0 : actualPayment;
      total[dphImpactRowIndex] += claim.sourceList.includes("DPH") ? actualPayment : 0;
    });

    // Process assumptions
    const assumptionMonthYear = convertDateToMMYY(claim.dueDate);
    const assumptionMonthIndex = months.indexOf(assumptionMonthYear);
    const amountTotal = claim?.amountTotal || 0;

    if (assumptionMonthIndex !== -1 && rowIndex !== -1) {
      assumptions[rowIndex][assumptionMonthIndex] += amountTotal;
      assumptions[totalRowIndex][assumptionMonthIndex] += claim.sourceList.includes("DPH") ? 0 : amountTotal;

      // Update cashflow assumptions separately
      assumptions[cashflowRowIndex][assumptionMonthIndex] += claim.sourceList.includes("DPH") ? 0 : amountTotal;
    }

    // Accumulate total assumptions for row and cashflow
    totalAssumption[rowIndex] += amountTotal;
    totalAssumption[totalRowIndex] += claim.sourceList.includes("DPH") ? 0 : amountTotal;
    totalAssumption[cashflowRowIndex] += claim.sourceList.includes("DPH") ? 0 : amountTotal;

    const dphMonthYear = convertDateToMMYY(claim.accountingDate);
    const dphMonthIndex = months.indexOf(dphMonthYear);
    if (dphMonthIndex !== -1) {
      assumptions[rowDefinitions.findIndex(row => row.code === "dphImpact")][dphMonthIndex] -= claim.amountVat;
    }
    totalAssumption[rowDefinitions.findIndex(row => row.code === "dphImpact")] -= claim.amountVat;
  });

  // Return rows with total and totalAssumption
  return rowDefinitions.map((rowDef, index) => {
    const sum = spendings[index].reduce((sum, value) => sum + value, 0);
    const sumAssumption = assumptions[index].reduce((sum, value) => sum + value, 0);
    return {
      id: index,
      label: rowDef.name,
      spendings: spendings[index],
      assumptions: assumptions[index],
      sum,
      sumAssumption,
      total: total[index],
      totalAssumption: totalAssumption[index]
    };
  });
};
const CashflowIncomes = ({ data, months, rowDefinitions, reference, onRowsChange }) => {
  const [rows, setRows] = useState(generateRows(data, months, rowDefinitions));
  const [hiddenColumns, setHiddenColumns] = useState({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    setRows(generateRows(data, months, rowDefinitions));
    const newHiddenColumns = generateHiddenColumns(months);
    setHiddenColumns(newHiddenColumns);

    if (apiRef.current) {
      apiRef.current.setState((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          columnVisibilityModel: newHiddenColumns,
        },
      }));
      apiRef.current.forceUpdate();
    }

    onRowsChange && onRowsChange();
  }, [months, data, rowDefinitions, onRowsChange]);

  const pinnedRows = {
    bottom: [
      rows[rowDefinitions.findIndex(row => row.code === "Total")],
      rows[rowDefinitions.findIndex(row => row.code === "dphImpact")],
      rows[rowDefinitions.findIndex(row => row.code === "cashflow")]
    ]
  };

  return (
    data.report && months.length > 0 && rowDefinitions.length > 0 && (
      <Paper>
        <Typography sx={{ paddingLeft: 2, paddingTop: 1.7, paddingBottom: 1.7, fontWeight: 'bold', backgroundColor: 'white', position: 'sticky', left: 0, fontSize: "0.85rem", height: '45px' }}>
          Příjmy dle fáze
        </Typography>
        <DataGridPro
          apiRef={apiRef}
          ref={reference}
          rows={rows || []}
          hideFooter
          disableRowSelectionOnClick
          columns={cashflowColumns(months)}
          columnGroupingModel={columnGroupingModel(months)}
          localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            pinnedColumns: {
              left: ["label"],
              right: ["sumAssumption", "sum", "totalAssumption", "total"],
            },
            columns: {
              columnVisibilityModel: {
                ...hiddenColumns,
              },
            },
          }}
          disableSelectionOnClick
          disableColumnReorder
          pinnedRows={pinnedRows}
          getCellClassName={(params) => {
            let classNames = '';

            if (params.value < 0) {
              classNames += 'negative ';
            }

            if (params.rowNode.type === 'pinnedRow') {
              classNames += params.colDef.headerName.includes('Skutečnost')
                ? 'pinned-row_spendings '
                : 'pinned-row ';
            }
            classNames += 'spendings ';

            return classNames.trim();
          }}
          sx={{
            '&.MuiDataGrid-root': { borderRadius: '0px', borderLeft: 'none', borderRight: 'none' },
            '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold', overflow: 'visible' },
            '& .MuiDataGrid-columnHeader': { borderRight: '1px solid rgba(224, 224, 224, 1)' },
            '& .spendings': { borderRight: '1px solid rgba(224, 224, 224, 1)' },
            [`.${gridClasses.cell}.pinned-row`]: { fontWeight: 'bold', backgroundColor: '#f5f5f5' },
            [`.${gridClasses.cell}.pinned-row_spendings`]: { fontWeight: 'bold', backgroundColor: '#f5f5f5', borderRight: '1px solid rgba(224, 224, 224, 1)' },
            '& .MuiDataGrid-cell.negative': { color: 'red' },
          }}
        />
      </Paper>
    )
  );
};

export default CashflowIncomes;