import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, IconButton, Tab, Tabs, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import {GlobalDataContext} from "../../GlobalDataProvider";
import Grid from "@mui/material/Grid2";
import toast from "react-hot-toast";
import {toastWithError} from "../../ToastWithError";
import {
    adjustUserValueList,
    generateUserValueListFullValueFirstMonth,
    generateUserValueListFullValueLastMonth,
    generateUserValueListSplitFirstLastMonth,
    generateUserValueListWithDistribution
} from "../../../utils/ValueGenerator";
import {useFormik} from "formik";
import {contractSchema, contractSchemaWithCompanyCode} from "../../../utils/Validations";
import {ContractInfo} from "./ContractInfo";
import {determineDistributionType} from "../../../utils/Comparator";
import {useAuth} from "../../../auth/AuthProvider";
import FinancialInfo from "../financialPlan/FinancialInfo";
import FinancialInvoices from "../financialPlan/FinancialInvoices";
import {api} from "../../../api/Api";
import {useNavigate} from "react-router-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%', // Default width for both PC and mobile
    maxHeight: '90vh', // Adjust the height as needed
    overflowY: 'auto', // Enable vertical scrolling
    boxShadow: 24,
    borderRadius: "10px",
    bgcolor: 'background.paper',
};
const ContractEdit = ({mode, selectedContract, opened, companyData, updateUserValue, close, updateRows, deleteContract, inputDictionaryList, isCostCenter}) => {
    const [listOfCompanies, setListOfCompanies] = useState(null);
    const {getUpdatedDataTime} = useContext(GlobalDataContext);
    const {setUpdateMessage} = useContext(GlobalDataContext);
    const [data, setData] = useState(companyData || null);
    const [dictionaryListOptions, setDictionaryListOptions] = useState(inputDictionaryList || null);
    const [tab, setTab] = useState(0);
    const [sortedCentres, setSortedCentres] = useState([]);
    const [oldCode, setOldCode] = useState(null);
    const [groupListOptions, setGroupListOptions] = useState([]);
    const [phaseListOptions, setPhaseListOptions] = useState([]);
    const [userValueList, setUserValueList] = useState([]);
    const [template, setTemplate] = useState("");
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            companyCode: data?.report?.companyDetail?.code || null,
            code: null,
            name: null,
            costCenter: null,
            scope: 0,
            costAssumption: 0,
            startDate: null,
            endDate: null,
            status: null,
            groupList: [],
            phaseList: [],
            sourceList: [],
            dictionaryItemCompanyCode: null,
        },
        validationSchema: mode === "fastCreate" ? contractSchemaWithCompanyCode: contractSchema,
        onSubmit: async (values) => {
            submit(values, mode === "fastCreate" ? "create" : mode);
        },
    });

    const handleClose = () => {
        close();
        formik.resetForm();
        setTab(0);
        setUserValueList([]);
        setTemplate("evenly");
    }

    useEffect(() => {
        companyData && formik.setFieldValue("companyCode", companyData?.report?.companyDetail?.code);
        companyData && setData(companyData)
        inputDictionaryList && setDictionaryListOptions(inputDictionaryList)
    }, [companyData, inputDictionaryList]);

    useEffect(() => {
        if(opened) {
        fetchListOfEntities(auth.token, "costCenterReport");
        fetchListOfEntities(auth.token, "companyReport");
        setData(companyData);
        }
    }, [opened]);

    useEffect(() => {
        if (formik.values.companyCode && mode === "fastCreate") {
          fetchCompanyData();
          fetchDictionary();
          formik.setFieldValue("groupList", []);
          formik.setFieldValue("phaseList", []);
          formik.setFieldValue("dictionaryItemCompanyCode", null);
        }
    }, [formik.values.companyCode, mode]);

    useEffect(() => {
        if (opened && data) {
        !selectedContract && fetchLabels();
        selectedContract && fetchUpdateData();
        }
    }, [data,opened, selectedContract]);

    useEffect(() => {
        if (formik.values.endDate && formik.values.startDate && formik.values.phaseList.length > 0 && tab === 1) {
                if (userValueList.length === 0 && mode !== "update") {
                    const generatedUserValueList = generateUserValueListWithDistribution(formik.values);
                    setUserValueList(generatedUserValueList);
                    setTemplate("evenly");
                }else {
                    const generatedUserValueList = getUserValueList();
                    setUserValueList(generatedUserValueList);
                }
        }
    }, [formik.values, mode, template, tab]);

    const fetchListOfEntities = async (token, entityType) => {
        try {
            const response = await api.get(`/${entityType}/list`);
            const listOfEntities = response.data.itemList.map(item => {
                // Check if code is numeric and, then pad it if single digit
                const code = /^\d+$/.test(item.code)
                  ? item.code.padStart(2, '0')
                  : item.code;
                return {
                    id: item.code,
                    code: code,
                    name: item.name,
                };
            });
            const sortedListOfEntities = [...listOfEntities].sort((a, b) => a.code.localeCompare(b.code));
            entityType === "costCenterReport" ? setSortedCentres(sortedListOfEntities) : setListOfCompanies(sortedListOfEntities);
        } catch (error) {
            console.error(`Error fetching ${entityType} list:`, error);
            if (error.response?.status === 401) navigate("/");
        }
    };

    const getUserValueList = () => {
        if (
          formik &&
          formik.values.phaseList &&
          formik.values.scope !== null && formik.values.scope !== undefined &&
          formik.values.costAssumption !== null && formik.values.costAssumption !== undefined &&
          formik.values.startDate &&
          formik.values.endDate
        ) {
            switch (template) {
              case "firstMonth":
                return generateUserValueListFullValueFirstMonth(formik.values)
              case "lastMonth":
                return generateUserValueListFullValueLastMonth(formik.values)
              case "firstAndLastMonth":
                return generateUserValueListSplitFirstLastMonth(formik.values)
                case "custom":
                    return adjustUserValueList(userValueList, formik.values)
              default:
                return generateUserValueListWithDistribution(formik.values)
            }
        }
        return userValueList;
    };

    const fetchUpdateData = async () => {
        try {
            formik.setValues({
                companyCode: data?.report?.companyDetail?.code || null,
                code: selectedContract.code === "" || mode === "create" ? null : selectedContract.code,
                name: selectedContract.name === "" ? null : selectedContract.name,
                costCenter: selectedContract.costCenter.toString() === "" ? null : selectedContract.costCenter,
                scope: parseFloat(selectedContract.scope),
                costAssumption: parseFloat(selectedContract.costAssumption),
                startDate: selectedContract.startDate === "" ? null : selectedContract.startDate,
                endDate: selectedContract.endDate === "" ? null : selectedContract.endDate,
                status: selectedContract.status === "" ? null : selectedContract.status,
                dictionaryItemCompanyCode: selectedContract.dictionaryItemCompanyCode === "" ? null : selectedContract.dictionaryItemCompanyCode,
                groupList: selectedContract.groupList,
                phaseList: selectedContract.phaseList,
                sourceList: [],
            });
            setOldCode(selectedContract.code === "" ? null : selectedContract.code);
            setUserValueList(selectedContract.userValueList);
            const template = determineDistributionType(
              selectedContract.userValueList,
              selectedContract.phaseList,
              selectedContract.startDate,
              selectedContract.endDate,
              selectedContract.costAssumption,
              selectedContract.scope
            )
            console.log("template", template);
            setTemplate(template);
            fetchLabels();
        } catch (error) {
            console.error(error);
        }
    };

    const fetchLabels = async () => {
        try {
            data.report.labelGroupList.forEach(label => {
                if (label.group) setGroupListOptions(label.labelList);
                if (label.phase) setPhaseListOptions(label.labelList);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const fetchDictionary = async () => {
        try {
            const dictionaryListResponse = await api.post(
              `/dictionary/list`,
              {companyCode: formik.values.companyCode}
            )
            let dictionaryList = dictionaryListResponse.data.dictionary.dictionaryItemList || [];
            let sortedDictionaryList = [...dictionaryList].sort((a, b) => a.name.localeCompare(b.name));
            setDictionaryListOptions(sortedDictionaryList || null);
        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) navigate("/");
            return [];
        }
    };

    const fetchCompanyData = async () => {
        try {
            const companyResponse = await api.post(
              `/companyReport/get`,
              {companyCode: formik.values.companyCode},
            )
            setData( companyResponse.data || null);
        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) navigate("/");
            return [];
        }
    };



    const submit = async (values, mode) => {
        try {
            setSaving(true);
            const { code, ...restValues } = values;
            const _userValueList = getUserValueList();
            const updatingDataset = await api.get(
              `/dataset/get`,
            );
            if (updatingDataset.data.updating) {
                setSaving(false);
                toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
                setUpdateMessage(updatingDataset.data.message);
                getUpdatedDataTime();
                return
            }
            const response = await api.post(
              `/contract/${mode}`,
              {
                  ...restValues,
                  userValueList: _userValueList,
                  code: mode === "update" ? oldCode : code,
                  updatedCode: mode === "update" ? code : null,
                  description: values.name,
              },
            );
            toast.success(`${mode === "create" ? "Zakázka byla vytvořena" : "Základní údaje byly upraveny"}!`);
            const newContract = response.data.flexiContract;
            const newRow = {
                code: newContract.code,
                costCenter: newContract.costCenter,
                name: newContract.name ?? newContract.description,
                //fixme remove description
                startDate: newContract.startDate,
                endDate: newContract.endDate,
                scope: newContract.scope,
                costAssumption: newContract.costAssumption,
                status: newContract.status,
                userValueList: newContract.userValueList,
                groupList: newContract.groupList,
                phaseList: newContract.phaseList,
                sourceList: newContract.sourceList,
                dictionaryItemCompanyCode: newContract.dictionaryItemCompanyCode,
                dictionary: dictionaryListOptions.find((item) => item.code === newContract.dictionaryItemCompanyCode)?.name
            }
            updateRows(mode === "update" ? oldCode : code, newRow, newContract.costCenter);
            if (mode === "update") submitUserValue(_userValueList);
            handleClose();
            setSaving(false);

        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) {
                navigate("/");
                return;
            }
            toastWithError(error);
            toast.error(`${mode === "create" ? "Zakázku se nepodařilo vytvořit" : "Základní údaje se nepodařilo upravit"}!`);
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        try {
            setDeleting(true);
            const updatingDataset = await api.get(`/dataset/get`);
            if (updatingDataset.data.updating) {
                setDeleting(false);
                toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
                setUpdateMessage(updatingDataset.data.message);
                return
            }
            const response = await api.post(
              `/contract/delete`,
              {
                  companyCode: data.report.companyDetail.code,
                  code: selectedContract.code
              },
            );
            toast.success(`Zakázka byla smazána!`);
            deleteContract(response.data.flexiContract.code, response.data.flexiContract.costCenter);
            handleClose();
            setDeleting(false);
        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) {
                navigate("/");
                return;
            }
            toastWithError(error);
            toast.error(`Zakázku se nepodařilo smazat!`);
            setDeleting();
        }
    };

    const submitUserValue = async (_userValueList) => {
        try {
            setSaving(true);
            const updatingDataset = await api.get(`/dataset/get`);
            if (updatingDataset.data.updating) {
                setSaving(false);
                toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
                setUpdateMessage(updatingDataset.data.message);
                getUpdatedDataTime();
                return
            }
            const contract = data.report.contractList.find(
              (contract) => contract.code === selectedContract.code
            );

            const response = (await api.post(
              "/contract/updateSettings",
              {
                  costCenterCode: contract.costCenter,
                  contractCode: selectedContract.code,
                  userValueList: _userValueList
              },
            )).data.contract;

            toast.success("Finanční plán byl upraven!");
            handleClose();
            updateUserValue(response.contractCode, response.userValueList, data.report.companyDetail?.code || "", response.costCenterCode);
            setSaving(false);
        } catch (error) {
            if (error.response?.status === 401) {
                navigate("/");
                return;
            }
            toastWithError(error);
            toast.error("Finanční plán se nepodařilo upravit!");
            console.error('Error updating time interval:', error);
            setSaving(false);
        }
    };


    return (
      <Modal open={opened} onClose={handleClose} aria-labelledby="user-edit-modal" aria-describedby="user-edit-form">
          <Box sx={{ ...style, maxWidth: tab === 2 ? undefined : "95vh" }} component="form">
              <Box sx={{position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1}}>
                  <Grid container>
                      <Grid size={6}>
                          <Typography variant="h5" sx={{p: 3, paddingBottom: "10px", fontWeight: 'bold'}}>
                              {mode === 'update' ? 'Úprava zakázky' : 'Vytváření nové zakázky'}
                          </Typography>
                      </Grid>
                      <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', p:1.5, pb:0}}>
                          <IconButton onClick={handleClose} >
                              <CloseIcon/>
                          </IconButton>
                      </Grid>
                  </Grid>
                  <Tabs value={tab} sx={{paddingLeft: 3, paddingRight: 3}}
                        onChange={(e, newValue) => setTab(newValue)}>
                      <Tab label="Základní údaje"/>
                      <Tab label="Finanční plán"/>
                      {mode === "update" && <Tab label="Faktury"/>}
                  </Tabs>
              </Box>
              <Box sx={{p: tab !== 2 ? 3 : 0.5, minHeight: '35vh', backgroundColor: '#f8f8f8'}}>
                  <Box hidden={tab !== 0}>
                      <ContractInfo mode={mode} formik={formik} listOfCompanies={listOfCompanies} sortedCentres={sortedCentres} groupListOptions={groupListOptions}
                                    phaseListOptions={phaseListOptions} dictionaryListOptions={dictionaryListOptions}/>
                  </Box>
                  <Box hidden={tab !== 1}>
                      <FinancialInfo
                        selectedContract={formik.values}
                      userValueList={userValueList}
                      setUserValueList={setUserValueList}
                                     company={data?.report || null}
                                     template={template}
                                     setTemplate={setTemplate}
                      />
                  </Box>
                  { mode === "update" &&
                      <Box hidden={tab !== 2}>
                          <FinancialInvoices
                            selectedContract={selectedContract}
                            company={data?.report || null}
                          />
                      </Box>
                  }

              </Box>
              <Box sx={{position: 'sticky', bottom: 0, backgroundColor: '#ffffff'}}>
                  <Grid container sx={{p: 3}}>
                      <Grid size={6}>
                          {mode === "update" && tab === 0 && !isCostCenter &&
                            <LoadingButton variant="outlined" disabled={saving} color="error" loading={deleting}
                                           loadingPosition="start" onClick={handleDelete} startIcon={<DeleteIcon/>}>
                                Smazat
                            </LoadingButton>

                          }
                      </Grid>
                      <Grid size={6} sx={{display: tab === 0 || (tab === 1 && mode === "update") ? 'flex' : 'none', justifyContent: 'flex-end', gap: 2}}>
                          <Button variant="outlined" onClick={handleClose} disabled={saving || deleting}
                                  alignSelf="flex-start"
                                  startIcon={<CloseIcon/>}>
                              Zrušit
                          </Button>
                          { !(isCostCenter && tab === 0 && mode === "update") &&
                          <LoadingButton variant="contained" loading={saving} disabled={deleting}
                                         loadingPosition="end" sx={{marginLeft: 2}}
                                         onClick={() => {
                                             if (isCostCenter && tab === 1) {
                                                 submitUserValue(userValueList);
                                             }else {
                                                 formik.handleSubmit();
                                             }
                                         }}
                                         endIcon={<SaveIcon/>}>
                              {isCostCenter && tab === 1 && mode === "update" ? 'Uložit finanční plán' : 'Uložit změny'}
                          </LoadingButton>
                          }
                      </Grid>
                  </Grid>
              </Box>
          </Box>
      </Modal>
    );
};
export default ContractEdit;
