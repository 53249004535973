import React, {useState} from "react";
import {Box, CircularProgress, Paper} from "@mui/material";
import {csCZ} from "@mui/x-data-grid/locales";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {phasesColumns} from "../data/DatatableColumns";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import {CustomToolbar, useFilteredColumns} from "./CustomToolbar";
import ContractEdit from "./popUps/contract/ContractEdit";

const Phases = ({data, isLoading, selectedCard, companyData, updateUserValue, company, deleteContract, updateOrAddContract, dictionaryListOptions, isCompany}) => {
  const [popUpOpened, setPopUpOpened] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [popUpMode, setPopUpMode] = useState("");
  console.log(company)

  const getTreeDataPath = (row) => row.hierarchy;

  const groupingColDef = {
    headerName: 'Období/Zakázka/Faktura',
    minWidth: 200,
  };

  const datatableStyling = {
    width: "100%",
    height: "77vh",
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    boxShadow: 0,
    borderColor: 'white',
    marginBottom: -2.5,
    backgroundColor: 'white',
    '&.MuiDataGrid-root': {
      borderRadius: '0px',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: "1px solid #EEEEEE",
    },
    '& .MuiDataGrid-withBorderColor': {
      borderColor: '#EEEEEE',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold !important', overflow: 'visible !important'
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)', color: '#1a3e72', fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483', color: '#1a3e72', fontWeight: '600',
    },
    '& .negative': {
      color: 'red'
    }

  };

  const handlePopUpOpen = () => {
    setPopUpOpened(true);
  }
  const handlePopUpClose = () => {
    setPopUpOpened(false);
    setSelectedContract(null);
  }

  const handleUpdateClick = (idPrefix) => {
    const selectedContract = companyData.report.contractList.find(row => row.code.startsWith(idPrefix));
    if (selectedContract) {
      setPopUpMode("update");
      setSelectedContract(selectedContract);
      handlePopUpOpen();
    } else {
      console.error("No matching contract found");
    }
  };

  const columns = useFilteredColumns(() => phasesColumns(handleUpdateClick));

  return (
    <div className="container" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <div className="datatable"
           style={{position: 'relative', width: "100%", justifyContent: "center", alignItems: "center"}}>
        {isLoading && selectedCard === "Cashflow" && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(3px)',
            zIndex: 9999,
          }}>
            <CircularProgress size={50}/>
          </div>

        )}
        {data && data.length > 0 ? (
          <Paper>
            <Grid container>
              <Grid size={6}>
                <Typography
                  sx={{
                    paddingLeft: 2,
                    paddingTop: 1.7,
                    paddingBottom: 1.7,
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    position: 'sticky',
                    left: 0,
                    fontSize: "0.85rem",
                    height: '45px',
                  }}
                >
                  Fáze
                </Typography>
              </Grid>
              <Grid size={6} alignItems="end"
                    sx={{alignItems: "center", justifyContent: "end", display: "flex", paddingRight: 2}}>
                <Box id="filter-panel"/>
              </Grid>

            </Grid>
            <DataGridPro
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              columnHeaderHeight={45}
              hideFooter
              rows={data || []}
              getCellClassName={(params) => {
                if (params.value < 0) {
                  return 'negative'
                }
              }}
              slots={{toolbar: CustomToolbar}}
              searchBar={true}
              columns={columns}
              sx={datatableStyling}
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                pagination: {paginationModel: {pageSize: 100}},
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
            />
          </Paper>
        ) : (
          <Typography>Žádná data pro fázi {selectedCard}</Typography>
        )}
        {selectedContract &&
        <ContractEdit mode={popUpMode} companyData={companyData} updateUserValue={updateUserValue} selectedContract={selectedContract} opened={popUpOpened} close={handlePopUpClose} deleteContract={deleteContract} updateRows={updateOrAddContract} inputDictionaryList={dictionaryListOptions} isCostCenter={!isCompany}/>}
      </div>
    </div>
  );
};

export default Phases;
