import { useGridPrivateApiContext } from "@mui/x-data-grid-pro/hooks/utils/useGridPrivateApiContext";
import { GRID_ROOT_GROUP_ID, gridRowTreeSelector, GridToolbarContainer, useGridSelector } from "@mui/x-data-grid-pro";
import React from "react";
import { Button } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

// Funkce pro odstranění diakritiky a mezer
const removeDiacritics = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Odstraní diakritiku
    .replace(/\s/g, "") // Odstraní mezery
    .toLowerCase(); // Převede na malá písmena
};

export function CustomToolbar() {
  const apiRef = useGridPrivateApiContext();
  const tree = useGridSelector(apiRef, gridRowTreeSelector);

  const expandAll = React.useCallback(() => {
    const newTree = { ...tree };
    const traverse = (id) => {
      const node = tree[id];
      if (node.type === 'group') {
        const newNode = { ...node, childrenExpanded: true };
        newTree[id] = newNode;
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(GRID_ROOT_GROUP_ID);
    apiRef.current.setState((state) => ({
      ...state,
      rows: {
        ...state.rows,
        tree: newTree,
      },
    }));
    apiRef.current.publishEvent('rowExpansionChange', newTree[GRID_ROOT_GROUP_ID]);
  }, [tree]);

  const collapseAll = React.useCallback(() => {
    const newTree = { ...tree };
    const traverse = (id) => {
      const node = tree[id];
      if (node.type === 'group') {
        const newNode = { ...node, childrenExpanded: false };
        newTree[id] = newNode;
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(GRID_ROOT_GROUP_ID);
    apiRef.current.setState((state) => ({
      ...state,
      rows: {
        ...state.rows,
        tree: newTree,
      },
    }));
    apiRef.current.publishEvent('rowExpansionChange', newTree[GRID_ROOT_GROUP_ID]);
  }, [tree]);

  return (
    <GridToolbarContainer sx={{ borderBottom: "1px solid #E0E0E0", p: 0.5 }}>
      <Button endIcon={<KeyboardDoubleArrowDownIcon />} onClick={expandAll}>Rozbalit</Button>
      <Button endIcon={<KeyboardDoubleArrowUpIcon />} onClick={collapseAll}>Sbalit</Button>
      <GridToolbarQuickFilter sx={{ ml: 1 }} />
    </GridToolbarContainer>
  );
}

// Hierarchický filtr s ignorací mezer a diakritiky
const hierarchicFilter = (filterValue) => {
  return (cellValue, filter) => {
    if (!cellValue && !filter.hierarchy) return false;

    const normalizedValue = removeDiacritics(filterValue.toString());
    const normalizedCellValue = removeDiacritics(cellValue?.toString() || "");

    return (
      (filter.hierarchy || []).some(item => removeDiacritics(item).includes(normalizedValue)) ||
      normalizedCellValue.includes(normalizedValue)
    );
  };
};

// Filtr pro datum (také ignoruje mezery a diakritiku)
const dateFilter = (filterValue) => {
  return (cellValue) => {
    if (!cellValue || !filterValue) return false;

    const formattedCellDate = removeDiacritics(
      new Intl.DateTimeFormat('cs-CZ').format(new Date(cellValue))
    );

    return formattedCellDate.includes(removeDiacritics(filterValue));
  };
};

// Použití filtrů na sloupce
export const useFilteredColumns = (getColumns) => {
  return React.useMemo(
    () =>
      getColumns().map((column) => {
        if (column.type === 'date') {
          return {
            ...column,
            getApplyQuickFilterFn: dateFilter, // Použití filtru pro datum
          };
        } else {
          return {
            ...column,
            getApplyQuickFilterFn: hierarchicFilter, // Použití hierarchického filtru
          };
        }
      }),
    [getColumns]
  );
};