import React from 'react';
import Grid from '@mui/material/Grid2';
import {formatAmount, formatDateAddSlash, removeTimezone} from "../../../utils/Formater";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import {FormControl, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {NumericFormat} from "react-number-format";
import {convertDateToMMYY} from "../../../utils/Coverter";
import {determineDistributionType} from "../../../utils/Comparator";

const FinancialInfo = ({selectedContract, userValueList, setUserValueList, company, template, setTemplate}) => {

  const calculateTotalScope = () => {
    let totalScope = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        totalScope += scope.value || 0;
      });
    });
    return totalScope;
  }

  const calculateTotalAssumption = () => {
    let totalAssumption = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        totalAssumption += assumption.value || 0;
      });
    });
    return totalAssumption;
  }

  const calculateActualPaymentSum = (contractCode) => {
    if (!company) {
      return 0;
    }
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match invoices by contractCode
      .reduce((sum, invoice) => {
        // Sum up all payments in the paymentList
        const totalPayments = invoice.paymentList
          ?.reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + totalPayments;
      }, 0);
  };

  const handleClearAssumption = () => {
    const newUserValueList = userValueList.map(userValue => ({
      ...userValue,
      businessMonthAssumptionList: userValue.businessMonthAssumptionList.map(assumption => ({
        ...assumption,
        value: 0
      }))
    }));

    setUserValueList(newUserValueList);
    setTemplate("custom");
  };

  const handleClearScope = () => {
    const newUserValueList = userValueList.map(userValue => ({
      ...userValue,
      businessMonthScopeList: userValue.businessMonthScopeList.map(scope => ({
        ...scope,
        value: 0
      }))
    }));

    setUserValueList(newUserValueList);
    setTemplate("custom");
  };

  const moveAssumptionToScope = () => {
    const newUserValueList = userValueList.map(item => ({
      ...item,
      businessMonthScopeList: [...item.businessMonthAssumptionList],
    }));

    setUserValueList(newUserValueList);
    const template = determineDistributionType(newUserValueList, selectedContract.phaseList, selectedContract.startDate, selectedContract.endDate, selectedContract.costAssumption, selectedContract.scope);
    setTemplate(template);
  };

  const moveScopeToAssumption = () => {
    const newUserValueList = userValueList.map(item => ({
      ...item,
      businessMonthAssumptionList: [...item.businessMonthScopeList],
    }));

    setUserValueList(newUserValueList);
    const template = determineDistributionType(newUserValueList, selectedContract.phaseList, selectedContract.startDate, selectedContract.endDate, selectedContract.costAssumption, selectedContract.scope);
    setTemplate(template);
  };

  const handleChangeAssumption = (values, sourceInfo, month, phase) => {
    if (sourceInfo.source === "prop") return;
    const newAssumptionValue = values.floatValue || 0;

    setUserValueList((prevList) => {
      const updatedList = prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthAssumptionList: userValue.businessMonthAssumptionList.map((item) =>
              item.businessMonth === month
                ? { ...item, value: newAssumptionValue }
                : item
            ),
          };
        }
        return userValue;
      });

      const template = determineDistributionType(
        updatedList,
        selectedContract.phaseList,
        selectedContract.startDate,
        selectedContract.endDate,
        selectedContract.costAssumption,
        selectedContract.scope
      );

      setTemplate(template);
      return updatedList;
    });
  };

  const handleChangeScope = (values, sourceInfo, month, phase) => {
    if (sourceInfo.source === "prop") return;
    const newScopeValue = values.floatValue || 0;

    setUserValueList((prevList) => {
      const updatedList = prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthScopeList: userValue.businessMonthScopeList.map((item) =>
              item.businessMonth === month ? { ...item, value: newScopeValue } : item
            ),
          };
        }
        return userValue;
      });

      const template = determineDistributionType(
        updatedList,
        selectedContract.phaseList,
        selectedContract.startDate,
        selectedContract.endDate,
        selectedContract.costAssumption,
        selectedContract.scope
      );

      setTemplate(template);
      return updatedList;
    });
  };

  const calculateActualPaymentMonthSum = (businessMonth, contractCode) => {
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match by contractCode
      .reduce((sum, invoice) => {
        // Sum up payment amounts for the specified businessMonth
        const monthlyPayments = invoice.paymentList
          ?.filter((payment) => convertDateToMMYY(payment.paymentDate) === businessMonth) // Match the month
          .reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + monthlyPayments;
      }, 0);
  };




  return (
    <Grid container sx={{
      height: '100%',
      paddingBottom: '13px',
      marginTop: -1,
      paddingTop: 2,
    }}>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Kód zakázky:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {selectedContract.code}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Číslo smlouvy:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {selectedContract.contractCode}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Platí od:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {removeTimezone(selectedContract.startDate)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Platí do:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {removeTimezone(selectedContract.endDate)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography
          variant="body1"
          sx={{marginBottom: '10px', color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'}}
        >
          <strong>Aktuální plán: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            textAlign: 'right',
            color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'
          }}
        >
          {formatAmount(selectedContract.costAssumption)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2.5}>
        <Typography
          variant="body1"
          sx={{marginBottom: '10px', color: selectedContract.scope >= 0 ? 'inherit' : 'red'}}
        >
          <strong>Aktuální smlouva: </strong>
        </Typography>
      </Grid>
      <Grid size={1.5}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            textAlign: 'right',
            color: selectedContract.scope >= 0 ? 'inherit' : 'red'
          }}
        >
          {formatAmount(selectedContract.scope)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red'
        }}>
          <strong>Nový plán: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red',
          textAlign: 'right'
        }}>
          {formatAmount(calculateTotalAssumption())}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red'
        }}>
          <strong>Nová smlouva: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red',
          textAlign: 'right'
        }}>
          {formatAmount(calculateTotalScope())}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red'}}>
          <strong>Realizováno: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red', textAlign: 'right'}}>
          {formatAmount(calculateActualPaymentSum(selectedContract.code))}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'}}>
          <strong>Zbývá
            dočerpat: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{
                      textAlign: 'right',
                      color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'
                    }}>
          {formatAmount(calculateTotalScope() - calculateActualPaymentSum(selectedContract.code))}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{color: 'inherit', paddingTop: 2.5}}>
          <strong>Šablona: </strong>
        </Typography>
      </Grid>
      <Grid size={10} sx={{color: 'inherit', paddingTop: 1.5}}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            value={template}
            onChange={(event) => setTemplate(event.target.value)}
            sx={{maxWidth: '200px'}}
          >
            <MenuItem value="evenly">Rovnoměrně</MenuItem>
            <MenuItem value="lastMonth">Na konci</MenuItem>
            <MenuItem value="firstMonth">Na začátku</MenuItem>
            <MenuItem value="firstAndLastMonth">Na konci a začátku</MenuItem>
            <MenuItem value="custom">Vlastní</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid size={1.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold">
          Fáze
        </Typography>
      </Grid>
      <Grid size={1.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold">
          Měsíc
        </Typography>
      </Grid>
      <Grid size={3.25} marginTop={1}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" fontWeight="bold" marginLeft={1}>
              Plán
            </Typography>
            <DeleteIcon
              onClick={() => handleClearAssumption()}
              sx={{ cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1 }}
            />
          </div>
          <div>
            <KeyboardDoubleArrowLeftIcon onClick={moveScopeToAssumption} sx={{ mr: 2, mt: 0.5, cursor: 'pointer' }} />
            <KeyboardDoubleArrowRightIcon onClick={moveAssumptionToScope} sx={{ mr: 3, mt: 0.5, cursor: 'pointer'}} />
          </div>
        </div>
      </Grid>
      <Grid size={3.25} marginTop={2}>
        <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
          <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
            Smlouva
          </Typography>
          <DeleteIcon onClick={() => handleClearScope()}
                      sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
        </div>
      </Grid>
      <Grid size={2.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
          Realizováno
        </Typography>
      </Grid>
      <Grid container spacing={2} paddingTop={1} width={"100%"}>
        {
          userValueList.length === 0 &&
          <Grid size={12} mt={4}>
            {(!selectedContract.startDate || !selectedContract.endDate || !selectedContract.phaseList.length > 0)  && <Typography variant="body2" sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>Nejprve vyplňte datum od/do a fáze</Typography>}
            {selectedContract.startDate && selectedContract.endDate && selectedContract.phaseList.length > 0 && <Typography variant="body2" sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>Načítám...</Typography>}
          </Grid>
        }
      {userValueList.length > 0 && userValueList.map((userValue, index) => userValue.businessMonthScopeList.map((scope, idx) => {
        const assumption = userValue.businessMonthAssumptionList[idx];
        return (<React.Fragment key={`${index}-${idx}`}>
          <Grid size={1.5}>
            <Typography variant="body2">{userValue.label}</Typography>
          </Grid>
          <Grid size={1.5}>
            <Typography
              variant="body2">{formatDateAddSlash(scope.businessMonth)}</Typography>
          </Grid>
          <Grid size={3.25}>
            <NumericFormat
              customInput={TextField} // Use MUI TextField as the input component
              value={assumption.value}
              onValueChange={(values, sourceInfo) => handleChangeAssumption(values, sourceInfo, assumption.businessMonth, userValue.label)}// Update the value when the user types
              onFocus={(e) => e.target.select()} // Select the text when the input is focused
              thousandSeparator=" " // Format with commas for thousands separator
              decimalSeparator="," // Use period for decimal separator
              decimalScale={0} // Limit to 2 decimal places
              allowNegative={false} // Disable negative values
              isNumericString={false} // Make sure it's treated as a numeric string
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
              }}
            />
          </Grid>
          <Grid size={3.25}>
            <NumericFormat
              customInput={TextField} // Use MUI TextField as the input component
              value={scope.value.toFixed(0)}
              onValueChange={(values, sourceInfo) => handleChangeScope(values, sourceInfo, scope.businessMonth, userValue.label)}// Update the value when the user types
              onFocus={(e) => e.target.select()} // Select the text when the input is focused
              thousandSeparator=" " // Format with commas for thousands separator
              decimalSeparator="," // Use period for decimal separator
              decimalScale={4} // Limit to 2 decimal places
              allowNegative={false} // Disable negative values
              isNumericString={true} // Make sure it's treated as a numeric string
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
              }}
            />
          </Grid>
          <Grid size={2.5}>
            <Typography variant="body2" sx={{
              color: (calculateActualPaymentMonthSum(
                scope.businessMonth,
                selectedContract.code,
              )) >= 0 ? 'inherit' : 'red'
            }}>{formatAmount(calculateActualPaymentMonthSum(
              scope.businessMonth,
              selectedContract.code,
            ))}</Typography>
          </Grid>
        </React.Fragment>);
      }))}
      </Grid>
    </Grid>

  );
};

export default FinancialInfo;
